import React from "react";

const ProbateProceedings = () => {
  return (
    <div className="container" style={{ paddingTop: "140px" }}>
      <h2 className="text-center">Ostavinski postupak</h2>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Postupak pokretanja
      </p>
      <p className="mt-3">
        Postupak se pokreće nakon smrti ostavitelja sljedećim koracima:
      </p>
      <ol className="mt-5">
        <li>
          Obratite se Odjeljenju za upravne poslove u Ministarstvu unutrašnjih
          poslova (MUP), gdje će vas upitati o detaljima vezanim za pokojnika,
          rodbinu, postojanje oporuke i procjenu vrijednosti ostavljene imovine.
        </li>
        <li>
          Nadležna služba MUP-a izrađuje smrtovnicu koja se predaje Osnovnom
          sudu zajedno s prijedlogom za otvaranje postupka ostavinske rasprave.
          U prijedlogu se navode nasljednici, njihove točne adrese i sastav
          nasljedstva, kao i bilo koji drugi podaci potrebni sudu za postupanje.
        </li>
        <li>
          Sud zakazuje ročište i poziva sve zainteresirane strane na adrese
          navedene u smrtovnici.
        </li>
      </ol>
      <p className="mt-3">Prilikom dolaska na ročište u sud potrebno je:</p>
      <ul className="mt-5">
        <li>Donijeti osobnu iskaznicu ili putovnicu;</li>
        <li>Ako postoji, donijeti oporuku pokojnika;</li>
        <li>Donijeti dokaze o imovini ostavitelja:</li>
        <li>
          Za nekretnine: priložiti izvatke iz zemljišnih knjiga, ne starije od 6
          mjeseci;
        </li>
        <li>
          Za pokretnine: npr. saobraćajnu dozvolu za vozila, štednu knjižicu za
          ušteđevinu u banci, certifikate itd.
        </li>
      </ul>
      <p className="mt-3">Tokom postupka</p>
      <p className="mt-3">
        Osnovni sud utvrđuje pravo na nasljedstvo i donosi odluku o
        nasljeđivanju. Na kraju postupka, sud određuje sudsku taksu koju
        nasljednici moraju platiti. Visina takse određuje se prema vrijednosti
        imovine ostavitelja, a nasljednici je plaćaju proporcionalno svojem
        dijelu nasljedstva.
      </p>
      <p className="mt-3">
        Za sve dodatne informacije, obratite se službenicima u pisarnici suda.
        Više informacija možete pronaći na web stranici:{" "}
        <a rel="noreferrer" href="http://sudovi.me" target="_blank">
          http://sudovi.me
        </a>
      </p>
      <h4>Životno osiguranje</h4>
      <p className="mt-3">
        Kada dođe do smrti osiguranika, iznos osiguranja dogovoren na polici
        životnog osiguranja isplaćuje se korisnicima polise (nasljednicima). U
        slučaju da je polisa obuhvaćala osiguranje za smrt, nasljednicima se
        isplaćuje dogovoreni iznos osiguranja uz dodatak pripadajuće dobiti.
      </p>
      <p className="mt-3">
        Ako korisnik osiguranja u slučaju smrti nije specificiran na polici
        životnog osiguranja, potrebno je dostaviti pravno rješenje o
        nasljeđivanju, na osnovu kojeg se nasljednicima isplaćuje osigurana
        suma.
      </p>
      <p className="mt-3">
        U situaciji kada je uzrok smrti bolest, zahtijeva se predaja dodatne
        dokumentacije (medicinski karton osiguranika) radi provjere istinitosti
        odgovora osiguranika na medicinska pitanja postavljena prilikom
        sklapanja ugovora o osiguranju, koja su dio ponude. Ako se utvrdi da
        osiguranik nije dao tačne informacije u odgovorima, isplata osigurane
        sume može biti odbijena (u slučaju da je neotkrivena bolest pri
        sklapanju ugovora utjecala na nastanak smrti) ili smanjena (ako
        prethodno poznata bolest nije imala utjecaj na smrtni ishod) za postotak
        kojim bi premija bila uvećana da je osiguravatelj bio upoznat s pravim
        zdravstvenim stanjem osiguranika.
      </p>
    </div>
  );
};

export default ProbateProceedings;
