import React from "react";

const ProcedureInCaseOfDeath = () => {
  return (
    <div className="container" style={{ paddingTop: "140px" }}>
      <h2 className="text-center">Postupci u slučaju smrti</h2>
      <h4 className="mt-5">Postupanje u slučaju smrti u domu</h4>
      <ul>
        <li className="mt-3">
          Ako se smrt dogodi unutar doma ili apartmana, procedura je kako
          slijedi:
        </li>
        <li>Potrebno je pozvati Službu hitne pomoći da potvrdi smrt.</li>
      </ul>
      <p className="mt-3">
        Nakon što Služba hitne pomoći potvrdi smrt i izda smrtovnicu, treba
        kontaktirati pogrebnu službu.
      </p>
      <p className="mt-3">
        Trebate utvrditi vrijeme sahrane, odabrati sahranu opremu i u Pogrebnoj
        službi razgovarati o svim detaljima s upraviteljem groblja o lokaciji
        sahrane, kao i o svim drugim aspektima sahrane. Sve dokumente vezane za
        prijavu smrti odnijeti kod lokalnog matičara ili nadležnoj komunalnoj
        službi za registraciju u Matičnu knjigu umrlih i dobivanje smrtovnice.
        Predati račun za pogrebnu opremu i usluge, ako je pokojnik bio osiguran,
        zajedno s osobnom iskaznicom i posljednjim primanjem pokojnika u Zavod
        za mirovinsko osiguranje za ostvarivanje prava.
      </p>
      <h4 className="mt-5">Smrt u medicinskoj ustanovi</h4>
      <p className="mt-3">
        U slučaju smrti unutar medicinske ustanove, procedura je sljedeća:
        Obratiti se odjelu Zdravstvenog centra gdje je pokojnik preminuo s
        potrebnim informacijama za prijavu smrti u Matični ured. Sa Smrtovnicom
        obratiti se osoblju mrtvačnice u bolnici. Ostaviti odjeću za sahranu
        pokojnika u mrtvačnici. U pogrebnoj službi utvrditi datum i vrijeme
        sahrane, odabrati pogrebnu opremu i dogovoriti sve detalje oko
        preuzimanja, transporta i sahrane pokojnika. Na groblju, gdje će se
        obaviti sahrana, dogovoriti sve detalje s nadležnom službom o mjestu
        sahrane, mjestu gdje će pokojnik biti sahranjen i sve druge detalje
        vezane za sahranu te registraciju pokojnika u Registar umrlih. Predati
        račun za pogrebnu opremu i usluge, ako je pokojnik bio osiguran, zajedno
        s osobnom iskaznicom pokojnika u Zavod za mirovinsko osiguranje za
        ostvarivanje prava.
      </p>
      <h4 className="mt-5">Postupci u slučaju sumnje na neprirnodnu smrt</h4>
      <p className="mt-3">
        Ako smrt nastupi u slučaju osobe koja je živjela sama ili se u trenutku
        smrti nalazila sama, obavezno je pozvati policiju radi potvrđivanja
        smrti i okolnosti pod kojima je do smrti došlo, kako bi se utvrdilo je
        li smrt bila prirodna ili nasilna. Isto tako, potrebno je postupiti i u
        slučaju nasilne smrti, ubistva ili samoubojstva (korištenjem vatrenog
        oružja ili na drugi način, vješanjem, utapanjem, itd.).
      </p>
    </div>
  );
};

export default ProcedureInCaseOfDeath;
