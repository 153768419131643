import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";

import { shortenString } from "../utils";
import ShareButtons from "./ShareButtons";

import { API_URL } from "../config";

function MediumPPozdrav({ post, modalIsOpen }) {
  const [imageSrc, setImageSrc] = useState(require("../assets/candle.png"));
  let userRole = localStorage.getItem("userRole");
  const [counter, setCounter] = useState(post.counter);
  const handleCandleClick = () => {
    // Toggle between two image sources
    if (imageSrc === require("../assets/candle.png")) {
      setImageSrc(require("../assets/candleColor.png"));
    } else {
      setImageSrc(require("../assets/candle.png"));
    }
    increaseCounter();
  };

  const increaseCounter = async () => {
    try {
      setCounter(post.counter + 1);
      await axios.post(
        `${API_URL}/api/posts/${post.id}/counter/up/`,
        {}
      );
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };
  if (!post) {
    return <div>Loading...</div>;
  }
  const deletePosts = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(
        `${API_URL}/api/posts/${post.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  return (
    <Container
      className="post d-flex flex-column"
      style={{
        height: `${modalIsOpen ? "auto" : "655px"}`,
        width: "100%",
        border: "3px solid black",
        paddingBottom: "0",

        paddingTop: `0%`,
      }}
    >
      <Row
        style={{
          fontStyle: "italic",
          border: "none",
          borderTop: "none",
          display: "flex",
          width: "100%",

          textAlign: "right",
          backgroundColor: "transparent",
          color: "gray",
          fontSize: "smaller",
        }}
      >
        <span className="pp-type" style={{ padding: "0", marginLeft: "2%" }}>
          poslednji pozdrav
        </span>
      </Row>

      <Container
        style={{ height: "fit-content", padding: "0", flex: 1 }}
        className="d-flex flex-column justify-content-center"
      >
        <Row
          style={{
            textAlign: "center",
            fontSize: "small",
            paddingLeft: "5%",
            paddingRight: "5%",
          }}
        >
          <p>{post.text_top}</p>
        </Row>
        <Row>
          <div style={{ margin: "0 auto", width: "fit-content" }}>
            {post.images.length !== 0 &&
              window.location.search !== "?print=true" && (
                <Carousel
                  showThumbs={false}
                  showArrows={false}
                  infiniteLoop={true}
                  autoPlay={true}
                  interval={2500}
                >
                  {post.images
                    .reduce((p, c) => p + c)
                    .split(",")
                    .map((imagePath, index) => (
                      <div
                        className="d-flex justify-content-center"
                        key={index}
                      >
                        <div
                          style={{
                            width: "120px",
                            height: "150px",
                            border: "3px solid black",
                            background: `url(${API_URL}/${imagePath})`,
                            backgroundSize: "cover",
                            backgroundPosition: "50% 50%",
                          }}
                        ></div>
                      </div>
                    ))}
                </Carousel>
              )}

            {post.images.length !== 0 &&
              window.location.search === "?print=true" && (
                <div
                  style={{
                    width: "120px",
                    height: "150px",
                    border: "3px solid black",
                    background: `url(${API_URL}/${post.images.reduce((p, c) => p + c).split(",")[0]
                      })`,
                    backgroundSize: "cover",
                    backgroundPosition: "50% 50%",
                  }}
                ></div>
              )}
          </div>
        </Row>
        <Row>
          <span
            style={{
              width: "100%",
              textAlign: "center",
              paddingTop: "3%",
            }}
          >
            {post.name}
          </span>
        </Row>
        {modalIsOpen && (
          <Row style={{ padding: "1% 3% 3%" }}>
            <div style={{ textAlign: "left", fontSize: "0.9rem" }}>
              <span className="main-text" style={{ textAlign: "left" }}>
                {post.main_text.split("\n").map((s, index) => (
                  <p key={index} className="main-text-line">
                    {s.trim()}
                  </p>
                ))}
              </span>
            </div>
          </Row>
        )}
        {!modalIsOpen && (
          <Row style={{ padding: "1% 3% 3%" }}>
            <div style={{ textAlign: "left", fontSize: "0.9rem" }}>
              <span className="main-text" style={{ textAlign: "left" }}>
                {post.main_text.includes("\n")
                  ? post.main_text
                    .split("\n")
                    .slice(0, 3)
                    .map((line, index, array) => (
                      <React.Fragment key={index}>
                        {shortenString(line, 150)}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))
                  : shortenString(post.main_text, 150)}
                {post.main_text.split("\n").length > 2 && "..."}
              </span>
            </div>
          </Row>
        )}
        {modalIsOpen && (
          <Row>
            {" "}
            <span style={{ textAlign: "center", fontStyle: "italic" }}>
              Upali svijeću
            </span>
          </Row>
        )}
        {modalIsOpen && (
          <Row>
            {" "}
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                alignItems: "center",
                borderRadius: "100%",
              }}
              onClick={handleCandleClick}
            >
              <img src={imageSrc} alt="Image" />
            </button>
          </Row>
        )}
        {modalIsOpen && (
          <Row style={{ paddingBottom: "0" }}>
            {" "}
            <span style={{ textAlign: "center" }}>{counter}</span>
          </Row>
        )}
        {modalIsOpen && (userRole === "superadmin" || userRole === "admin") && (
          <Col>
            <button
              style={{ marginLeft: "3%" }}
              className="btn btn-danger"
              onClick={() => deletePosts()}
            >
              Obriši
            </button>
          </Col>
        )}
        {modalIsOpen && (
          <Row style={{ padding: "1% 3% 3%" }}>
            <div
              style={{
                fontWeight: "bold",
                paddingLeft: "3%",
                paddingRight: "3%",
              }}
            >
              {
                <div
                  className="p-text-bottom"
                  style={{ fontWeight: "bold", paddingTop: "1%" }}
                >
                  {post.text_bottom.split("\n").map((s, index) => (
                    <p key={index} className="bottom-text-line">
                      {s.trim()}
                    </p>
                  ))}
                </div>
              }
            </div>
          </Row>
        )}
        {!modalIsOpen && (
          <Row style={{ padding: "1% 3% 3%" }}>
            <div
              style={{
                fontWeight: "bold",
                paddingLeft: "3%",
                paddingRight: "3%",
              }}
            >
              {
                <div
                  className="p-text-bottom"
                  style={{ fontWeight: "bold", paddingTop: "1%" }}
                >
                  {shortenString(post.text_bottom, 200)}
                </div>
              }
            </div>
          </Row>
        )}
        {modalIsOpen && <ShareButtons id={post.id} />}
      </Container>
    </Container>
  );
}

export default MediumPPozdrav;
