import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import { FaCcVisa, FaCcMastercard } from "react-icons/fa";
import VISALOGO from "../assets/visalogo.png";

function Footer() {
  return (
    <footer className="bg-dark container-fluid py-4">
      <Container>
        <Row className="pb-3">
          <Col xs={12} md={4} lg={4}>
            <h4 className="footer-title">Korisni linkovi</h4>
            <Nav.Link
              style={{ color: "white", textDecoration: "underline" }}
              className="footer-link"
              href="/postupci-u-slucaju-smrti"
            >
              Postupci u slučaju smrti
            </Nav.Link>
            {/* Add more links here */}
            <Nav.Link
              style={{ color: "white", textDecoration: "underline" }}
              className="footer-link"
              href="/tradicija-i-obicaji"
            >
              Tradicija i običaji
            </Nav.Link>{" "}
            <Nav.Link
              style={{ color: "white", textDecoration: "underline" }}
              className="footer-link"
              href="/pravo-zakona-o-fondu-pio"
            >
              Pravo zakona o fondu pio
            </Nav.Link>{" "}
            <Nav.Link
              style={{ color: "white", textDecoration: "underline" }}
              className="footer-link"
              href="/ostavinski-postupak"
            >
              Ostavinski postupak i životno osiguranje
            </Nav.Link>{" "}
            <Nav.Link
              style={{ color: "white", textDecoration: "underline" }}
              className="footer-link"
              href="/pogrebne-usluge"
            >
              Pogrebne usluge
            </Nav.Link>
          </Col>
          <Col lg={4} xs={12} md={4}>
            {/* Add more links here */}
            <h4 className="footer-title">Uslovi korišćenja</h4>{" "}
            <Nav.Link
              style={{ color: "white", textDecoration: "underline" }}
              className="footer-link"
              href="/about-us"
            >
              O nama
            </Nav.Link>
            <Nav.Link
              style={{ color: "white", textDecoration: "underline" }}
              className="footer-link"
              href="/terms"
            >
              Uslovi
            </Nav.Link>{" "}
            <Nav.Link
              style={{ color: "white", textDecoration: "underline" }}
              className="footer-link"
              href="/upiti-zalbe"
            >
              Upiti i žalbe
            </Nav.Link>
            <Nav.Link
              style={{ color: "white", textDecoration: "underline" }}
              className="footer-link"
              href="/cookies"
            >
              Kolačići i privatnost
            </Nav.Link>
          </Col>
          <Col lg={4} xs={12} md={4} className="text-center">
            {/* Add more links here */}
            <h4 className="footer-title">Company</h4>
            <div
              style={{ color: "white", textDecoration: "underline" }}
              className="footer-link col-12"
            >
              Name: Daily Press doo
            </div>
            <br />
            <div
              style={{ color: "white", textDecoration: "underline" }}
              className="footer-link"
            >
              Registration number: 02255383
            </div>
            <br />
            <div
              style={{ color: "white", textDecoration: "underline" }}
              className="footer-link"
            >
              VAT number: 30/31-01515-3
            </div>
            <br />
            <div
              style={{ color: "white", textDecoration: "underline" }}
              className="footer-link"
            >
              Address: Trg Nezavisnosti bb, 81000 Podgorica, Montenegro
            </div>
            <br />
            <div
              style={{ color: "white", textDecoration: "underline" }}
              className="footer-link"
            >
              Customer support:{" "}
              <a href="mailto:umrlice@vijesti.me">umrlice@vijesti.me</a>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div
          className="footer-icons-row"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="me-2 mb-2">
            <div
              style={{ color: "white" }}
              /*https://rs.visa.com/*/
            >
              {/*<FaCcVisa size={40} />*/}
              <img
                src={require("../assets/visalogo.png")}
                style={{
                  padding: "10px",
                  width: "80px",
                  height: "55px",
                  backgroundColor: "#ffffff",
                }}
                alt="VISA"
              />
              {/*<span style={{ marginLeft: "3%", fontSize: "14px" }}>
                    Visa
                  </span>*/}
            </div>
          </div>
          <div className="me-2 mb-2">
            <div
              style={{ textAlign: "center", color: "white" }}
              /*https://www.mastercard.rs/sr-rs.html*/
            >
              {/*<FaCcMastercard size={40} />*/}
              <img
                src={require("../assets/mastercard-logo.png")}
                style={{
                  padding: "10px",
                  height: "55px",
                  backgroundColor: "#ffffff",
                }}
                alt="MasterCard"
              />
              <span style={{ marginLeft: "3%", fontSize: "14px" }}>
                {/*MasterCard™*/}
              </span>
            </div>
          </div>
          <div className="me-2 mb-2">
            <div
              style={{ textAlign: "center", color: "white" }}
              /*https://www.mastercard.rs/sr-rs.html*/
            >
              {/*<FaCcMastercard size={40} />*/}
              <img
                src={require("../assets/maestrocard-logo.png")}
                style={{
                  padding: "10px",
                  height: "55px",
                  backgroundColor: "#ffffff",
                }}
                alt="MASTERCARD"
              />
              <span style={{ marginLeft: "3%", fontSize: "14px" }}>
                {/*Maestro*/}
              </span>
            </div>
          </div>
          <div className="me-2 mb-2">
            <a
              href="https://www.allsecure.rs"
              target="_blank"
              style={{ fontSize: "14px", color: "white" }}
            >
              <img
                src={require("../assets/allsecure-certified.png")}
                style={{ width: "100px", height: "55px" }}
                alt="MASTERCARD CERTIFIED"
              />
              {/*Certified By Visa MasterCard*/}
            </a>
          </div>
          <div className="me-2 mb-2">
            <a
              href="https://www.hipotekarnabanka.com"
              target="_blank"
              style={{ fontSize: "14px", color: "white" }}
            >
              <img
                src={require("../assets/hb_logo.png")}
                style={{
                  width: "100px",
                  padding: "5px",
                  height: "55px",
                  backgroundColor: "white",
                }}
                alt="BANK LOGO"
              />
              {/*Hipotekarna Banka*/}
            </a>
          </div>
          <div className="me-2 mb-2">
            <div
              /*https://rs.visa.com/*/
              style={{ fontSize: "14px", color: "white" }}
            >
              <img
                src={require("../assets/visa_secure.png")}
                style={{
                  width: "60px",
                  height: "55px",
                  backgroundColor: "white",
                }}
                alt="BANK LOGO"
              />
              {/* Visa secure*/}
            </div>
          </div>
          <div className="me-2 mb-2">
            <div
              /*https://www.mastercard.rs/sr-rs.html*/
              style={{ fontSize: "14px", color: "white" }}
            >
              <img
                src={require("../assets/mc_idcheck.png")}
                style={{
                  width: "60px",
                  height: "55px",
                  backgroundColor: "white",
                  padding: "2px",
                }}
                alt="BANK LOGO"
              />
              {/*Master card id check*/}
            </div>
          </div>
        </div>
      </Container>
      <div className="text-center">
        <small>Copyright &copy; Vijesti 2023</small>
      </div>
    </footer>
  );
}

export default Footer;
