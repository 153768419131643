import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
import axios from "axios";
import { shortenString } from "../utils";
import { API_URL } from "../config";

import ShareButtons from "./ShareButtons";
function BigPomen({ post, modalIsOpen }) {
  const userRole = localStorage.getItem("userRole");
  const [imageSrc, setImageSrc] = useState(require("../assets/candle.png"));
  const [counter, setCounter] = useState(post.counter);

  const handleCandleClick = async () => {
    // Toggle between two image sources
    if (imageSrc === require("../assets/candle.png")) {
      setImageSrc(require("../assets/candleColor.png"));
    } else {
      setImageSrc(require("../assets/candle.png"));
    }

    try {
      await increaseCounter();
    } catch (error) {
      console.log("Error updating counter:", error);
    }
  };

  const increaseCounter = async () => {
    try {
      await axios.post(
        `${API_URL}/api/posts/${post.id}/counter/up/`
      );
      setCounter(post.counter + 1);
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  if (!post) {
    return <div>Loading...</div>;
  }

  const deletePosts = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(
        `${API_URL}/api/posts/${post.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  return (
    <Container
      style={{
        border: "7px double black",
        backgroundColor: "white",
        width: "100%",
        marginLeft: "0",
        position: "relative",
      }}
      id={`post-${post.id}`}
      className="post"
      data-aos="fade-up"
    >
      <Row>
        <p
          style={{
            fontStyle: "italic",
            border: "none",
            borderTop: "none",
            textAlign: "right",
            backgroundColor: "white",
            color: "gray",
            paddingRight: "0.5vh",
            fontSize: "smaller",
          }}
        >
          {post.type}
        </p>
      </Row>
      <Row>
        <p style={{ padding: "2%" }}>{post.text_top}</p>
      </Row>
      <p style={{ textAlign: "center", color: "gray", fontSize: "small" }}>
        {post.header_text}
      </p>{" "}
      <Row style={{ paddingTop: "0%" }}>
        <Col className="d-flex justify-content-around"></Col>
        <Col className="d-flex justify-content-around">
          {post.images.length !== 0 && (
            <Carousel
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={2500}
            >
              {post.images
                .reduce((p, c) => p + c)
                .split(",")
                .map((imagePath, index) => (
                  <div className="d-flex justify-content-center" key={index}>
                    <div
                      style={{
                        width: "120px",
                        height: "150px",
                        border: "3px solid black",
                        background: `url(${API_URL}/${imagePath})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50% 50%",
                      }}
                    ></div>
                  </div>
                ))}
            </Carousel>
          )}

          {post.images.length === 0 && (
            <div
              style={{
                width: "150px",
                height: "150px",
              }}
            ></div>
          )}
        </Col>
        <Col className="d-flex justify-content-around"></Col>
      </Row>
      <Row style={{ paddingTop: "1%" }}>
        <Col
          style={{
            letterSpacing: "1.2px",
            fontSize: "larger",
          }}
          className="d-flex justify-content-around"
        >
          {post.name}
        </Col>
      </Row>
      {modalIsOpen && (
        <Row>
          <p
            className="main-text"
            style={{ padding: "2%", color: "black", textAlign: "left" }}
          >
            <span className="main-text" style={{ textAlign: "left" }}>
              {post.main_text.split("\n").map((s, index) => (
                <p key={index} className="main-text-line">
                  {s.trim()}
                </p>
              ))}
            </span>
          </p>
        </Row>
      )}
      {!modalIsOpen && (
        <Row>
          <p
            className="main-text"
            style={{ padding: "2%", color: "black", textAlign: "left" }}
          >
            <span className="main-text" style={{ textAlign: "left" }}>
              {post.main_text.includes("\n")
                ? post.main_text
                  .split("\n")
                  .slice(0, 3)
                  .map((line, index, array) => (
                    <React.Fragment key={index}>
                      {shortenString(line, 200)}
                      {index < array.length - 1 && <br />}
                    </React.Fragment>
                  ))
                : shortenString(post.main_text, 200)}
              {post.main_text.split("\n").length > 2 && "..."}
            </span>
          </p>
        </Row>
      )}
      {modalIsOpen && (
        <Row>
          {" "}
          <span style={{ textAlign: "center", fontStyle: "italic" }}>
            Upali svijeću
          </span>
        </Row>
      )}
      {modalIsOpen && (
        <Row>
          {" "}
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              alignItems: "center",
              borderRadius: "100%",
              width: "100%",
            }}
            onClick={handleCandleClick}
          >
            <img src={imageSrc} alt="Image" />
          </button>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{ paddingBottom: "0" }}>
          {" "}
          <span style={{ textAlign: "center" }}>{counter}</span>
        </Row>
      )}
      {!modalIsOpen && (
        <Row style={{ padding: "2%", paddingLeft: "1%" }}>
          <b>
            <p style={{ textAlign: "right", fontWeight: "bold" }}>
              {shortenString(post.text_bottom, 50)}
            </p>
          </b>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{ padding: "2%", paddingLeft: "1%" }}>
          <b>
            <div style={{ textAlign: "right", fontWeight: "bold" }}>
              {
                <p
                  className="p-text-bottom"
                  style={{ fontWeight: "bold", paddingTop: "1%" }}
                >
                  {post.text_bottom.split("\n").map((s, index) => (
                    <p key={s} className="bottom-text-line">
                      {s.trim()}
                    </p>
                  ))}
                </p>
              }
            </div>
          </b>
        </Row>
      )}
      {modalIsOpen && (userRole === "superadmin" || userRole === "admin") && (
        <Col>
          <button
            style={{ marginLeft: "3%" }}
            className="btn btn-danger"
            onClick={() => deletePosts()}
          >
            Obriši
          </button>
        </Col>
      )}
      {modalIsOpen && <ShareButtons id={post.id} />}
    </Container>
  );
}

export default BigPomen;
