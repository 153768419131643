import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import QuarterUmrlica from "./QuarterUmrlica";
import Modal from "react-modal";
import SmallPoslednjiPozdrav from "./SmallPoslednjiPozdrav";
import MediumPPozdrav from "./MediumPPozdrav";
import PoslednjiPozdrav from "./PoslednjiPozdrav";
import Umrlica from "./Umrlica";
import SmallUmrlica from "./SmallUmrlica";
import BigUmrlica from "./BigUmrlica";
import Pomen from "./Pomen";
import MediumPomen from "./MediumPomen";
import BigPomen from "./BigPomen";
import Reklama from "./Reklama";
import MediumReklama from "./MediumReklama";
import QrUmrlica from "./QuarterUmrlica";
import TinyReklama from "./TinyReklama";
import { useHistory } from "react-router-dom";
import QuarterPomen from "./QuarterPomen";
import QuarterPP from "./QuarterPP";
import DatePicker from "react-date-picker";
import { API_URL } from "../config";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

Modal.setAppElement("#root");

const limit = 10;
function HomePage({
  ads,
  posts,
  cities,
  count,
  setDateCreated,
  dateCreated,
  offset,
  setOffset,
  selectedCity,
  setSelectedCity,
  isLoggedIn,
}) {
  const [selectedPost, setSelectedPost] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalSize, setModalSize] = useState(0);
  const [modalState, setModalState] = useState(false);
  const [relatedPosts, setRelatedPosts] = useState([]);

  const today = new Date();

  // Get the current day, month, and year
  const currentDay = today.getDate();
  const currentMonth = today.getMonth() + 1; // Month is 0-indexed, so we add 1
  const currentYear = today.getFullYear();
  let userRole = localStorage.getItem("userRole");

  useEffect(() => {
    if (selectedPost && modalIsOpen) {
      fetchRelatedPosts(selectedPost.id);
    }
  }, [selectedPost, modalIsOpen]);

  useEffect(() => {
    document.querySelector("#root>footer").style.display = modalIsOpen
      ? "none"
      : "block";
  }, [modalIsOpen]);

  const fetchRelatedPosts = async (postId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/posts/?parent=${postId}`
      );

      setRelatedPosts(response.data);
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };
  window.onpopstate = () => {
    if (modalIsOpen === true) {
      setModalIsOpen(false);
    }
  };
  const stateSetter = () => {
    if (modalState === false) {
      setModalState(true);
    } else {
      setModalState(false);
    }
  };
  const history = useHistory();

  const openModal = useCallback((post, type, size) => {
    setSelectedPost(post);
    setModalType(type);
    setModalSize(size);
    setModalIsOpen(true);
    window.scrollTo({
      top: 0,
    });
    setModalState(true);

    window.history.pushState(null, "", `/posts/${post.id}`);
  }, []);

  const closeModal = useCallback(() => {
    setModalType("");
    setModalSize(0);
    setModalIsOpen(false);
    stateSetter();

    history.goBack();
  }, []);

  const goToFormaZaPopunjavanje = (selectedPost) => {
    if (!isLoggedIn) {
      window.location.href = "/login";
    } else if (selectedPost) {
      localStorage.setItem("postId", selectedPost.id);
      window.location.href = "/redirect-form";
    }
  };
  const totalPages = Math.ceil(count / limit);

  const handlePreviousPage = () => {
    setOffset(offset - limit);
  };

  const handleUpdateButton = (id) => {
    window.location.href = `/posts/update/${id}`;
  };

  const size_VELIKI = 3;
  const size_SREDNJI = 2;
  const size_MALI = 1;

  const LARGE = 3;
  const MEDIUM = 2;
  const SMALL = 1;
  const TINY = 4;
  const WHOLE = "WHOLE";
  const ROW = "ROW";
  const COLUMN = "COLUMN";
  const TINY_COLUMN = "TINY_COLUMN";

  const handleDecrementDay = () => {
    const currentDate = new Date();
    const dd = currentDate.getDate();
    const mm = currentDate.getMonth() + 1; // getMonth() is zero-based
    const yyyy = currentDate.getFullYear();
    const formattedDate = new Date(yyyy, mm - 1, dd - 1);
    if (!dateCreated) {
      const newDate = new Date(dateCreated);
      newDate.setDate(formattedDate.getDate() - 1);
      setDateCreated(formattedDate);
    } else {
      const newDate = new Date(dateCreated);
      newDate.setDate(dateCreated.getDate() - 1);
      setDateCreated(newDate);
    }
  };

  const handleIncrementDay = () => {
    const currentDate = new Date();
    const dd = currentDate.getDate();
    const mm = currentDate.getMonth() + 1; // getMonth() is zero-based
    const yyyy = currentDate.getFullYear();
    const formattedDate = new Date(yyyy, mm - 1, dd + 1);
    if (!dateCreated) {
      const newDate = new Date(dateCreated);
      newDate.setDate(formattedDate.getDate() + 1);
      setDateCreated(formattedDate);
    } else {
      const newDate = new Date(dateCreated);
      newDate.setDate(dateCreated.getDate() + 1);
      setDateCreated(newDate);
    }
  };

  function getPostOrder(POSTS) {
    let order = [];
    for (let i = 0; i < POSTS.length; i++) {
      order[i] = { post: null, type: WHOLE, size: LARGE };
    }

    POSTS.forEach((post) => {
      for (let i = 0; i < order.length; i++) {
        const slot = order[i];

        if (post.size === LARGE) {
          if (slot.type === WHOLE && slot.post === null) {
            order[i] = { post: post, type: WHOLE, size: LARGE };
            break;
          }
        } else if (post.size === MEDIUM) {
          if (slot.type === WHOLE && slot.post === null) {
            order[i] = {
              left: { post: post, type: WHOLE, size: MEDIUM },
              right: null,
              type: ROW,
            };
            break;
          } else if (slot.type === ROW) {
            if (slot.left === null) {
              order[i].left = { post: post, type: WHOLE, size: MEDIUM };
              break;
            }
            if (slot.right === null) {
              order[i].right = { post: post, type: WHOLE, size: MEDIUM };
              break;
            }
          }
        } else if (post.size === SMALL) {
          if (slot.type === WHOLE && slot.post === null) {
            order[i] = {
              left: {
                top: { post: post, type: WHOLE, size: SMALL },
                bottom: null,
                type: COLUMN,
              },
              right: null,
              type: ROW,
            };
            break;
          } else if (slot.type === ROW) {
            if (slot.left === null) {
              order[i].left = {
                top: { post: post, type: WHOLE, size: SMALL },
                bottom: null,
                type: COLUMN,
              };
              break;
            } else if (slot.left.type === COLUMN) {
              if (slot.left.top === null) {
                order[i].left.top = { post: post, type: WHOLE, size: SMALL };
                break;
              } else if (slot.left.bottom === null) {
                order[i].left.bottom = { post: post, type: WHOLE, size: SMALL };
                break;
              }
            }

            if (slot.right === null) {
              order[i].right = {
                top: { post: post, type: WHOLE, size: SMALL },
                bottom: null,
                type: COLUMN,
              };
              break;
            } else if (slot.right.type === COLUMN) {
              if (slot.right.top === null) {
                order[i].right.top = { post: post, type: WHOLE, size: SMALL };
                break;
              } else if (slot.right.bottom === null) {
                order[i].right.bottom = {
                  post: post,
                  type: WHOLE,
                  size: SMALL,
                };
                break;
              }
            }
          }
        } else if (post.size === TINY) {
          if (slot.type === WHOLE && slot.post === null) {
            order[i] = {
              left: {
                top: {
                  top: { post: post, type: WHOLE, size: TINY },
                  bottom: null,
                  type: TINY_COLUMN,
                },
                bottom: null,
                type: COLUMN,
              },
              right: null,
              type: ROW,
            };
            break;
          } else if (slot.type === ROW) {
            if (slot.left === null) {
              order[i].left = {
                top: {
                  top: { post: post, type: WHOLE, size: TINY },
                  bottom: null,
                  type: TINY_COLUMN,
                },
                bottom: null,
                type: COLUMN,
              };
              break;
            } else if (slot.left.type === COLUMN) {
              if (slot.left.top === null) {
                order[i].left.top = {
                  top: { post: post, type: WHOLE, size: TINY },
                  bottom: null,
                  type: TINY_COLUMN,
                };
                break;
              } else if (slot.left.top.type === TINY_COLUMN) {
                if (slot.left.top.top === null) {
                  order[i].left.top.top = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                } else if (slot.left.top.bottom === null) {
                  order[i].left.top.bottom = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                }
              }

              if (slot.left.bottom === null) {
                order[i].left.bottom = {
                  top: { post: post, type: WHOLE, size: TINY },
                  bottom: null,
                  type: TINY_COLUMN,
                };
                break;
              } else if (slot.left.bottom.type === TINY_COLUMN) {
                if (slot.left.bottom.top === null) {
                  order[i].left.bottom.top = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                } else if (slot.left.bottom.bottom === null) {
                  order[i].left.bottom.bottom = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                }
              }
            }

            if (slot.right === null) {
              order[i].right = {
                top: {
                  top: { post: post, type: WHOLE, size: TINY },
                  bottom: null,
                  type: TINY_COLUMN,
                },
                bottom: null,
                type: COLUMN,
              };
              break;
            } else if (slot.right.type === COLUMN) {
              if (slot.right.top === null) {
                order[i].right.top = {
                  top: { post: post, type: WHOLE, size: TINY },
                  bottom: null,
                  type: TINY_COLUMN,
                };
                break;
              } else if (slot.right.top.type === TINY_COLUMN) {
                if (slot.right.top.top === null) {
                  order[i].right.top.top = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                } else if (slot.right.top.bottom === null) {
                  order[i].right.top.bottom = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                }
              }

              if (slot.right.bottom === null) {
                order[i].right.bottom = {
                  top: { post: post, type: WHOLE, size: TINY },
                  bottom: null,
                  type: TINY_COLUMN,
                };
                break;
              } else if (slot.right.bottom.type === TINY_COLUMN) {
                if (slot.right.bottom.top === null) {
                  order[i].right.bottom.top = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                } else if (slot.right.bottom.bottom === null) {
                  order[i].right.bottom.bottom = {
                    post: post,
                    type: WHOLE,
                    size: TINY,
                  };
                  break;
                }
              }
            }
          }
        }
      }
    });

    order.forEach((block, i) => {
      if (block.type === ROW) {
        if (block.left.type === COLUMN) {
          if (block.left.bottom && block.left.bottom.type === TINY_COLUMN) {
            const adRight = block.right === null;
            const adLeftBottomBottom = block.left.bottom.bottom === null;
            if (adRight && adLeftBottomBottom) {
              order[i].right = {
                type: COLUMN,
                top: {
                  type: TINY_COLUMN,
                  top: block.left.bottom.top,
                  bottom: null,
                },
                bottom: "hidden",
              };
              order[i].left.bottom.bottom = "hidden";
              order[i].left.bottom.top = "hidden";
            }
          } else if (block.left.top && block.left.top.type === TINY_COLUMN) {
            const adRight = block.right === null;
            const adLeftBottom = block.left.bottom === null;
            const adLeftTopBottom = block.left.top.bottom === null;
            if (adRight && adLeftBottom && adLeftTopBottom) {
              order[i].left.bottom = "hidden";
              order[i].left.top.bottom = "hidden";
              order[i].right = {
                type: COLUMN,
                top: {
                  type: TINY_COLUMN,
                  top: null,
                  bottom: "hidden",
                },
                bottom: "hidden",
              };
            }
          } else {
            const adRight = block.right === null;
            const adLeftBottom = block.left.bottom === null;
            if (adRight && adLeftBottom) {
              order[i].left.bottom = "hidden";
              order[i].right = {
                type: COLUMN,
                top: null,
                bottom: "hidden",
              };
            }
          }
        }
      }
    });

    return order;
  }

  function renderPostRow(post) {
    return (
      <div
        key={0}
        className="row"
        style={{
          paddingRight: "0",
          padding: "0",
          marginLeft: "0",
          marginRight: "0",
        }}
      >
        <div className="col-lg-6 col-sm-12 col-md-12" style={{}}>
          {renderPost(post.left, MEDIUM)}
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12" style={{}}>
          {renderPost(post.right, MEDIUM)}
        </div>
      </div>
    );
  }

  function renderPostColumn(post) {
    return (
      <div key={0} className="col">
        <div className="row-6">{renderPost(post.top, SMALL)}</div>
        <div className="row-6">{renderPost(post.bottom, SMALL)}</div>
      </div>
    );
  }

  function renderPostTinyColumn(post) {
    return (
      <div key={0} className="col">
        <div className="row-6">{renderPost(post.top, TINY)}</div>
        <div className="row-6">{renderPost(post.bottom, TINY)}</div>
      </div>
    );
  }

  function renderPostWhole(post) {
    if (post.post === null) {
      return;
    }

    if (post.size === LARGE) {
      if (post.post.type === "pomen") {
        return (
          <div
            key={post.post.id}
            style={{ paddingTop: "20px", width: "100%" }}
            onClick={() => openModal(post.post, "pomen", 3)}
          >
            <BigPomen modalIsOpen={modalIsOpen} post={post.post} />
          </div>
        );
      } else if (post.post.type === "umrlica") {
        return (
          <div
            key={post.post.id}
            style={{ paddingTop: "20px", width: "100%" }}
            onClick={() => openModal(post.post, "umrlica", 3)}
          >
            <BigUmrlica modalIsOpen={modalIsOpen} post={post.post} />
          </div>
        );
      } else if (post.post.type === "poslednji_pozdrav") {
        return (
          <div
            key={post.post.id}
            style={{ paddingTop: "20px", width: "100%" }}
            onClick={() => openModal(post.post, "poslednji_pozdrav", 3)}
          >
            <PoslednjiPozdrav
              id={`post - ${post.id}`}
              userRole={userRole}
              modalIsOpen={modalIsOpen}
              post={post.post}
            />
          </div>
        );
      }
    } else if (post.size === MEDIUM) {
      if (post.post.type === "umrlica") {
        return (
          <div
            key={post.post.id}
            style={{ marginTop: "20px" }}
            onClick={() => openModal(post.post, "umrlica", 2)}
          >
            <Umrlica
              id={`post - ${post.id}`}
              userRole={userRole}
              modalIsOpen={modalIsOpen}
              post={post.post}
            />
          </div>
        );
      } else if (post.post.type === "poslednji_pozdrav") {
        return (
          <div
            id={`post - ${post.id}`}
            key={post.post.id}
            style={{ marginTop: "20px" }}
            onClick={() => openModal(post.post, "poslednji_pozdrav", 2)}
          >
            <MediumPPozdrav
              userRole={userRole}
              modalIsOpen={modalIsOpen}
              post={post.post}
            />
          </div>
        );
      } else if (post.post.type === "pomen") {
        return (
          <div
            key={post.post.id}
            style={{ marginTop: "20px" }}
            onClick={() => openModal(post.post, "pomen", 2)}
          >
            <MediumPomen
              id={`post - ${post.id}`}
              userRole={userRole}
              modalIsOpen={modalIsOpen}
              post={post.post}
            />
          </div>
        );
      }
    } else if (post.size === SMALL) {
      if (post.post.type === "umrlica") {
        return (
          <div
            style={{ paddingTop: "20px" }}
            key={post.post.id}
            onClick={() => openModal(post.post, "umrlica", 1)}
          >
            <SmallUmrlica
              id={`post - ${post.id}`}
              userRole={userRole}
              modalIsOpen={modalIsOpen}
              post={post.post}
            />
          </div>
        );
      } else if (post.post.type === "poslednji_pozdrav") {
        return (
          <div
            key={post.post.id}
            style={{ paddingTop: "20px" }}
            onClick={() => openModal(post.post, "poslednji_pozdrav", 1)}
          >
            <SmallPoslednjiPozdrav
              id={`post - ${post.id}`}
              userRole={userRole}
              modalIsOpen={modalIsOpen}
              post={post.post}
            />
          </div>
        );
      } else if (post.post.type === "pomen") {
        return (
          <div
            key={post.post.id}
            style={{ paddingTop: "20px" }}
            onClick={() => openModal(post.post, "pomen", 1)}
          >
            <Pomen
              id={`post - ${post.id}`}
              userRole={userRole}
              modalIsOpen={modalIsOpen}
              post={post.post}
            />
          </div>
        );
      }
    } else if (post.size === TINY) {
      if (post.post.type === "umrlica") {
        return (
          <div
            style={{ paddingTop: "20px" }}
            key={post.post.id}
            onClick={() => openModal(post.post, "umrlica", 4)}
          >
            <QrUmrlica
              id={`post - ${post.id}`}
              userRole={userRole}
              modalIsOpen={modalIsOpen}
              post={post.post}
            />
          </div>
        );
      } else if (post.post.type === "pomen") {
        return (
          <div
            style={{ paddingTop: "20px" }}
            key={post.post.id}
            onClick={() => openModal(post.post, "pomen", 4)}
          >
            <QuarterPomen
              id={`post - ${post.id}`}
              userRole={userRole}
              modalIsOpen={modalIsOpen}
              post={post.post}
            />
          </div>
        );
      } else if (post.post.type === "poslednji_pozdrav") {
        return (
          <div
            style={{ paddingTop: "20px" }}
            key={post.post.id}
            onClick={() => openModal(post.post, "poslednji_pozdrav", 4)}
          >
            <QuarterPP
              id={`post - ${post.id}`}
              userRole={userRole}
              modalIsOpen={modalIsOpen}
              post={post.post}
            />
          </div>
        );
      }
    } else {
      return <p>invalid post size</p>;
    }
  }

  function renderPost(post, size, index) {
    if (post === null || post === "hidden") {
      const displayStyle = {
        display: post === "hidden" ? "none" : "block",
      };
      if (size === MEDIUM) {
        return (
          <div key={index} style={displayStyle}>
            <MediumReklama ads={ads} />
          </div>
        );
      } else if (size === SMALL) {
        return (
          <div key={index} style={displayStyle}>
            <Reklama ads={ads} />
          </div>
        );
      } else if (size === TINY) {
        return (
          <div key={index} style={displayStyle}>
            <TinyReklama ads={ads} />
          </div>
        );
      }
    } else if (post.type === WHOLE) {
      return renderPostWhole(post, index);
    } else if (post.type === ROW) {
      return renderPostRow(post, index);
    } else if (post.type === COLUMN) {
      return renderPostColumn(post, index);
    } else if (post.type === TINY_COLUMN) {
      return renderPostTinyColumn(post, index);
    }
  }

  const exportPostAsImage = () => {
    window.open(`/posts/${selectedPost.id}?print=true`);
  };

  return (
    <Container
      className="post-list"
      style={{ paddingRight: "8%", paddingLeft: "8%", paddingTop: "120px" }}
    >
      <div className="filters">
        <div className="filter-group">
          <label
            style={{ color: "black" }}
            className="city-filter-label"
            htmlFor="cityFilter"
          >
            Filtriraj po gradu
          </label>
          <select
            style={{ color: "black" }}
            id="cityFilter"
            className="filter-select"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            <option value={""}>Odaberi grad</option>
            {cities.map((city) => (
              <option style={{ color: "black" }} key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
        <div className="filter-group">
          {" "}
          <label className="date-filter-label" htmlFor="dateFilter">
            Filtriraj po datumu
          </label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              style={{
                border: "none",
                background: "transparent",
                padding: "0",
                margin: "0",
              }}
              onClick={handleDecrementDay}
            >
              <img
                src={require("../assets/lijevo.png")}
                alt="neki tekst"
                style={{
                  height: "20px",
                  border: "none",
                  margin: "0",
                }}
              />
            </button>
            <DatePicker
              dayPlaceholder={JSON.stringify(currentDay)}
              monthPlaceholder={JSON.stringify(currentMonth)}
              yearPlaceholder={JSON.stringify(currentYear)}
              name="death_date"
              onChange={setDateCreated}
              value={dateCreated}
              className="FormaZaPopunjavanje-input"
              style={{ marginTop: "8px" }}
              format="dd/MM/y"
            />
            <button
              style={{
                border: "none",
                background: "transparent",
                padding: "0",
                margin: "0",
              }}
              onClick={handleIncrementDay}
            >
              <img
                src={require("../assets/desno.png")}
                alt="neki tekst"
                style={{
                  height: "20px",
                  border: "none",
                  margin: "0",
                }}
              />
            </button>
          </div>
        </div>
      </div>
      {posts.length === 0 && (
        <div
          style={{
            minHeight: "430px",
            paddingTop: "10px",
            textAlign: "center",
          }}
        >
          Nema objava za izabranu pretragu
        </div>
      )}
      {getPostOrder(posts).map((row, rowIndex) => {
        return !modalIsOpen && row !== null && row.constructor !== Array ? (
          <div
            key={rowIndex}
            className="row"
            style={{ paddingRight: "0px !important" }}
          >
            {" "}
            {/*odje*/}
            {renderPost(row, 1)}
          </div>
        ) : row !== null && row.length === 2 ? (
          row[0] !== null &&
            row[1] !== null &&
            row[0].size === 1 &&
            row[1].size === 1 ? (
            <div key={rowIndex} className="row">
              <div className="col-6 col-sm-12 col-md-12">
                {renderPost(row[0], size_SREDNJI)}
                {renderPost(row[1], size_SREDNJI)}
              </div>
              <div className="col-lg-6 col-sm-12 col-md-12"></div>
            </div>
          ) : (
            <div key={rowIndex} className="row">
              <div className="col-lg-6 col-sm-12 col-md-12">
                {renderPost(row[0], size_SREDNJI)}
              </div>
              <div className="col-lg-6 col-sm-12 col-md-12">
                {renderPost(row[1], size_SREDNJI)}
              </div>
            </div>
          )
        ) : row !== null && row.length === 3 ? (
          row[0] !== null && row[0].size === 2 ? (
            <div key={rowIndex} className="row">
              <div className="col-lg-6 col-sm-12 col-md-12">
                {renderPost(row[0], size_SREDNJI)}
              </div>
              <div
                className="col-lg-6 col-sm-12 col-md-12"
                style={{ paddingRight: "0" }}
              >
                {renderPost(row[1], size_MALI)} {renderPost(row[2], size_MALI)}
              </div>
            </div>
          ) : (
            <div key={rowIndex} className="row">
              <div className="col-lg-6 col-sm-12 col-md-12">
                {renderPost(row[0], size_MALI)} {renderPost(row[1], size_MALI)}
              </div>
              <div className="col-lg-6 col-sm-12 col-md-12">
                {renderPost(row[2], size_SREDNJI)}
              </div>
            </div>
          )
        ) : row !== null && row.length === 2 ? (
          <div key={rowIndex} className="row">
            <div className="col-lg-6 col-sm-12 col-md-12">
              {renderPost(row[0], size_MALI)} {renderPost(row[1], size_MALI)}
            </div>
            <div className="col-lg-6 col-sm-12 col-md-12">
              {renderPost(row[2], size_MALI)} {renderPost(row[3], size_MALI)}
            </div>
          </div>
        ) : (
          <div key={rowIndex} className="row prazno"></div>
        );
      })}

      <div className="pagination">
        {offset !== 0 && posts.lenght > 1 && (
          <button
            style={{
              backgroundColor: "black",
              color: "white",
              borderRadius: "8px",
            }}
            onClick={handlePreviousPage}
            disabled={offset === 0} // Disable on first page
          >
            Prethodna
          </button>
        )}
        {/* Render clickable numbers for pages */}
        {totalPages > 1 && (
          <div>
            {Array.from({ length: totalPages }).map((_, index) => {
              if (index <= 4 || index === totalPages - 1) {
                return (
                  <button
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      borderRadius: "8px",
                    }}
                    key={index}
                    onClick={() => setOffset(index * limit)}
                    disabled={index * limit === offset}
                  >
                    {index + 1}
                  </button>
                );
              } else if (index === 5) {
                return <span key={index}>...</span>;
              } else {
                return null;
              }
            })}

            {offset + limit < count && (
              <button
                style={{
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "8px",
                }}
                onClick={() => setOffset(offset + limit)}
              >
                Sledeća
              </button>
            )}
          </div>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentlabel="Post Modal"
        size="xl"
      >
        <Container
          className="modal-mobile-view"
          style={{
            paddingLeft: `${selectedPost?.size === 3 ? "7%" : "10%"}`,
            paddingRight: `${selectedPost?.size === 3 ? "7%" : "10%"}`,
          }}
        >
          <Row style={{ margin: "0" }}>
            {selectedPost &&
              modalType === "poslednji_pozdrav" &&
              modalSize === 3 && (
                <PoslednjiPozdrav
                  modalIsOpen={modalIsOpen}
                  post={selectedPost}
                />
              )}

            {selectedPost &&
              modalType === "poslednji_pozdrav" &&
              modalSize === 2 && (
                <MediumPPozdrav modalIsOpen={modalIsOpen} post={selectedPost} />
              )}

            {selectedPost &&
              modalType === "poslednji_pozdrav" &&
              modalSize === 1 && (
                <SmallPoslednjiPozdrav
                  modalIsOpen={modalIsOpen}
                  post={selectedPost}
                />
              )}
            {selectedPost && modalType === "umrlica" && modalSize === 1 && (
              <SmallUmrlica
                userRole={userRole}
                modalIsOpen={modalIsOpen}
                post={selectedPost}
              />
            )}
            {selectedPost && modalType === "umrlica" && modalSize === 2 && (
              <Umrlica
                userRole={userRole}
                modalIsOpen={modalIsOpen}
                post={selectedPost}
              />
            )}

            {selectedPost && modalType === "umrlica" && modalSize === 3 && (
              <BigUmrlica
                userRole={userRole}
                modalIsOpen={modalIsOpen}
                post={selectedPost}
              />
            )}

            {selectedPost && modalType === "pomen" && modalSize === 1 && (
              <Pomen modalIsOpen={modalIsOpen} post={selectedPost} />
            )}

            {selectedPost && modalType === "pomen" && modalSize === 2 && (
              <MediumPomen modalIsOpen={modalIsOpen} post={selectedPost} />
            )}

            {selectedPost && modalType === "pomen" && modalSize === 3 && (
              <BigPomen modalIsOpen={modalIsOpen} post={selectedPost} />
            )}

            {selectedPost && modalType === "pomen" && modalSize === 4 && (
              <QuarterPomen modalIsOpen={modalIsOpen} post={selectedPost} />
            )}
            {selectedPost &&
              modalType === "poslednji_pozdrav" &&
              modalSize === 4 && (
                <QuarterPP modalIsOpen={modalIsOpen} post={selectedPost} />
              )}
          </Row>
          {selectedPost && modalType === "umrlica" && modalSize === 4 && (
            <QuarterUmrlica modalIsOpen={modalIsOpen} post={selectedPost} />
          )}

          <button
            style={{
              float: "right",
              borderRadius: "8px",
              marginTop: "2%",
              padding: "5px",
              backgroundColor: "black",
              color: "white",
              border: "2px solid black",
            }}
            onClick={closeModal}
          >
            Zatvori
          </button>

          <div>
            {userRole === "superadmin" && (
              <button
                style={{
                  marginTop: "2%",
                  padding: "5px",
                  backgroundColor: "transparent",
                  borderRadius: "8px",
                  color: "black",
                  border: "2px solid black",
                  marginRight: "1%",
                }}
                onClick={() => handleUpdateButton(selectedPost.id)}
              >
                Uredi objavu
              </button>
            )}
            {userRole === "admin" && (
              <button
                style={{
                  marginTop: "2%",
                  padding: "5px",
                  backgroundColor: "transparent",
                  borderRadius: "8px",
                  color: "black",
                  border: "2px solid black",
                  marginRight: "1%",
                }}
                onClick={() => handleUpdateButton(selectedPost.id)}
              >
                Uredi objavu
              </button>
            )}
            <button
              className="stampaj-button"
              style={{
                marginTop: "2%",
                padding: "5px",
                backgroundColor: "transparent",
                color: "black",
                borderRadius: "8px",

                border: "2px solid black",
              }}
              onClick={() => exportPostAsImage(`post-${selectedPost.id}`)}
            >
              Štampaj
            </button>

            {selectedPost && modalType === "umrlica" && (
              <button
                className="modal-btn-predaj-pp"
                onClick={() => goToFormaZaPopunjavanje(selectedPost)}
              >
                Predaj poslednji pozdrav
              </button>
            )}
          </div>

          {selectedPost && selectedPost.type === "umrlica" && (
            <Row style={{ margin: "0" }}>
              <h3 style={{ textAlign: "center", paddingTop: "30px" }}>
                Poslednji pozdravi
              </h3>
              {relatedPosts
                .filter((relatedPost) => relatedPost.parent === selectedPost.id)
                .map((relatedPost) => {
                  if (
                    relatedPost.type === "poslednji_pozdrav" &&
                    relatedPost.size === 1
                  ) {
                    return (
                      <Col
                        style={{ paddingTop: "20px" }}
                        sm={12}
                        md={12}
                        lg={6}
                        key={relatedPost.id}
                      >
                        <SmallPoslednjiPozdrav post={relatedPost} />
                      </Col>
                    );
                  } else if (
                    relatedPost.type === "poslednji_pozdrav" &&
                    relatedPost.size === 2
                  ) {
                    return (
                      <Col
                        style={{ paddingTop: "20px" }}
                        sm={12}
                        md={12}
                        lg={6}
                        key={relatedPost.id}
                      >
                        <MediumPPozdrav post={relatedPost} />
                      </Col>
                    );
                  } else if (
                    relatedPost.type === "poslednji_pozdrav" &&
                    relatedPost.size === 3
                  ) {
                    return (
                      <Col
                        style={{ paddingTop: "20px" }}
                        sm={12}
                        md={12}
                        lg={12}
                        key={relatedPost.id}
                      >
                        <PoslednjiPozdrav post={relatedPost} />
                      </Col>
                    );
                  }
                  return null; // Add this line to handle cases where the post type or size doesn't match
                })}
            </Row>
          )}
        </Container>
      </Modal>
    </Container>
  );
}

export default HomePage;
