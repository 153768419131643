export function customValidation(
  formType,
  formSize,
  values,
  setIsSubmiting,
  setStep,
  uploadedImagePaths,
  NotificationManager,
  deathDate,
  birthDate,
  step
) {
  if (formType === "Umrlica") {
    if (formSize === 3) {
      if (
        values.top_text === "" ||
        values.icon === "" ||
        values.name === "" ||
        deathDate === "" ||
        birthDate === "" ||
        uploadedImagePaths.length === 0 ||
        values.bottom_text === "" ||
        values.city === "Izaberi grad" ||
        values.main_text === ""
      ) {
        setIsSubmiting(false);
        NotificationManager.error("Sva obilježena polja moraju biti popunjena");
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setStep(step + 1);
      }
    } else if (formSize === 2) {
      if (
        values.icon === "" ||
        values.name === "" ||
        deathDate === "" ||
        birthDate === "" ||
        uploadedImagePaths.length === 0 ||
        values.bottom_text === "" ||
        values.city === "Izaberi grad" ||
        values.main_text === ""
      ) {
        setIsSubmiting(false);
        NotificationManager.error("Sva obilježena polja moraju biti popunjena");
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setStep(step + 1);
      }
    } else if (formSize === 1) {
      if (
        values.top_text === "" ||
        values.name === "" ||
        deathDate === "" ||
        birthDate === "" ||
        values.main_text === "" ||
        uploadedImagePaths.length === 0 ||
        values.city === "Izaberi grad" ||
        values.bottom_text === ""
      ) {
        setIsSubmiting(false);
        NotificationManager.error("Sva obilježena polja moraju biti popunjena");
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setStep(step + 1);
      }
    } else if (formSize === 4) {
      if (
        values.name === "" ||
        deathDate === "" ||
        values.city === "Izaberi grad" ||
        birthDate === "" ||
        values.bottom_text === ""
      ) {
        setIsSubmiting(false);
        NotificationManager.error("Sva obilježena polja moraju biti popunjena");
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setStep(step + 1);
      }
    }
  } else if (formType === "Pomen") {
    if (formSize === 3) {
      if (
        values.name === "" ||
        values.bottom_text === "" ||
        values.city === "Izaberi grad" ||
        uploadedImagePaths.length === 0 ||
        values.main_text === ""
      ) {
        setIsSubmiting(false);
        NotificationManager.error("Sva obilježena polja moraju biti popunjena");
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setStep(step + 1);
      }
    } else if (formSize === 2) {
      if (
        values.top_text === "" ||
        values.name === "" ||
        values.city === "Izaberi grad" ||
        values.bottom_text === "" ||
        values.main_text === "" ||
        uploadedImagePaths.length === 0 ||
        values.header_text === ""
      ) {
        setIsSubmiting(false);
        NotificationManager.error("Sva obilježena polja moraju biti popunjena");
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setStep(step + 1);
      }
    } else if (formSize === 1) {
      if (
        values.top_text === "" ||
        values.name === "" ||
        values.city === "Izaberi grad" ||
        uploadedImagePaths.length === 0 ||
        values.header_text === ""
      ) {
        setIsSubmiting(false);
        NotificationManager.error("Sva obilježena polja moraju biti popunjena");
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setStep(step + 1);
      }
    } else if (formSize === 4) {
      if (
        values.name === "" ||
        values.city === "Izaberi grad" ||
        values.header_text === "" ||
        values.main_text === ""
      ) {
        setIsSubmiting(false);
        NotificationManager.error("Sva obilježena polja moraju biti popunjena");
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setStep(step + 1);
      }
    }
  } else if (formType === "Poslednji pozdrav") {
    if (formSize === 3) {
      if (
        values.top_text === "" ||
        values.name === "" ||
        values.city === "Izaberi grad" ||
        uploadedImagePaths.length === 0 ||
        values.bottom_text === "" ||
        values.main_text === ""
      ) {
        setIsSubmiting(false);
        NotificationManager.error("Sva obilježena polja moraju biti popunjena");
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setStep(step + 1);
      }
    } else if (formSize === 2) {
      if (
        values.top_text === "" ||
        values.name === "" ||
        values.city === "Izaberi grad" ||
        values.bottom_text === "" ||
        uploadedImagePaths.length === 0 ||
        values.main_text === ""
      ) {
        setIsSubmiting(false);
        NotificationManager.error("Sva obilježena polja moraju biti popunjena");
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setStep(step + 1);
      }
    } else if (formSize === 1) {
      if (
        values.top_text === "" ||
        values.name === "" ||
        values.city === "Izaberi grad" ||
        values.main_text === "" ||
        uploadedImagePaths.length === 0 ||
        values.bottom_text === ""
      ) {
        setIsSubmiting(false);
        NotificationManager.error("Sva obilježena polja moraju biti popunjena");
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setStep(step + 1);
      }
    } else if (formSize === 4) {
      if (values.name === "") {
        setIsSubmiting(false);
        NotificationManager.error("Sva obilježena polja moraju biti popunjena");
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setIsSubmiting(false);
        setStep(step + 1);
      }
    }
    setIsSubmiting(false);
  }
  setIsSubmiting(false);
}
