import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
import { shortenString } from "../utils";
import ShareButtons from "./ShareButtons";
import { API_URL } from "../config";

function PoslednjiPozdrav({ post, modalIsOpen }) {
  const userRole = localStorage.getItem("userRole");
  const [imageSrc, setImageSrc] = useState(require("../assets/candle.png"));
  const [counter, setCounter] = useState(post.counter);
  const handleCandleClick = () => {
    // Toggle between two image sources
    if (imageSrc === require("../assets/candle.png")) {
      setImageSrc(require("../assets/candleColor.png"));
    } else {
      setImageSrc(require("../assets/candle.png"));
    }
    increaseCounter();
  };

  const increaseCounter = async () => {
    try {
      setCounter(post.counter + 1);
      await axios.post(
        `${API_URL}/api/posts/${post.id}/counter/up/`,
        {}
      );
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };
  if (!post) {
    return <div>Loading...</div>;
  }
  const deletePosts = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(
        `${API_URL}/api/posts/${post.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  return (
    <Container
      data-aos={"fade-up"}
      id="pp"
      style={{
        border: "3px solid black",
        backgroundColor: "white",
        marginTop: "20px",
      }}
      className="post"
    >
      {" "}
      <Row>
        <p
          style={{
            fontStyle: "italic",
            border: "none",
            borderTop: "none",
            textAlign: "right",
            backgroundColor: "white",
            color: "gray",
            fontSize: "smaller",
            paddingRight: "0.5vh",
          }}
        >
          {post.type === "poslednji_pozdrav" ? "poslednji pozdrav" : post.type}
        </p>
      </Row>
      <Row style={{ paddingTop: "3%", fontSize: "medium" }}>
        <p style={{ textAlign: "center" }}>{post.text_top}</p>
      </Row>
      <Row style={{ paddingTop: "0", justifyContent: "center" }}></Row>
      <Row style={{ paddingTop: "3%" }}>
        <Col className=" justify-content-around"></Col>
        <Col className="d-flex justify-content-around">
          {post.images.length !== 0 && (
            <Carousel
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={2500}
            >
              {post.images
                .reduce((p, c) => p + c)
                .split(",")
                .map((imagePath, index) => (
                  <div className="d-flex justify-content-center" key={index}>
                    <div
                      style={{
                        width: "120px",
                        height: "150px",
                        border: "3px solid black",
                        background: `url(${API_URL}/${imagePath})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50% 50%",
                      }}
                    ></div>
                  </div>
                ))}
            </Carousel>
          )}
        </Col>
        <Col className="d-flex justify-content-around"></Col>
      </Row>
      <Row style={{ paddingTop: "2%", paddingBottom: "0%" }}>
        <Col className="d-flex justify-content-around"></Col>
        <Col
          style={{
            fontWeight: "bold",
            letterSpacing: "1.2px",
          }}
          className="d-flex justify-content-around"
        >
          {post.name}
        </Col>
        <Col className="d-flex justify-content-around"></Col>
      </Row>
      {!modalIsOpen && (
        <Row style={{ paddingTop: "2%", paddingBottom: "3%" }}>
          <div style={{ textAlign: "left" }}>
            <span className="main-text" style={{ textAlign: "left" }}>
              {post.main_text.includes("\n")
                ? post.main_text
                  .split("\n")
                  .slice(0, 3)
                  .map((line, index, array) => (
                    <React.Fragment key={index}>
                      {shortenString(line, 200)}
                      {index < array.length - 1 && <br />}
                    </React.Fragment>
                  ))
                : shortenString(post.main_text, 200)}
              {post.main_text.split("\n").length > 2 && "..."}
            </span>
          </div>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{ paddingTop: "2%", paddingBottom: "3%" }}>
          <div style={{ textAlign: "left" }}>
            {" "}
            {post.main_text.split("\n").map((s, index) => (
              <p key={index} className="main-text-line">
                {s.trim()}
              </p>
            ))}
          </div>
        </Row>
      )}
      {modalIsOpen && (
        <Row>
          {" "}
          <span style={{ textAlign: "center", fontStyle: "italic" }}>
            Upali svijeću
          </span>
        </Row>
      )}
      {modalIsOpen && (
        <Row>
          {" "}
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              alignItems: "center",
              borderRadius: "100%",
            }}
            onClick={handleCandleClick}
          >
            <img src={imageSrc} alt="Image" />
          </button>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{ paddingBottom: "0" }}>
          {" "}
          <span style={{ textAlign: "center" }}>{counter}</span>
        </Row>
      )}
      {!modalIsOpen && (
        <Row style={{ float: "right" }}>
          <p style={{ fontWeight: "bold" }}>
            {shortenString(post.text_bottom, 50)}
          </p>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{ float: "right" }}>
          <div style={{ paddingBottom: "5%", fontWeight: "bold" }}>
            {post.text_bottom.split("\n").map((s, index) => (
              <p className="bottom-text-line">{s.trim()}</p>
            ))}
          </div>
        </Row>
      )}
      <Row style={{ paddingTop: "5%" }}></Row>
      {modalIsOpen && (userRole === "admin" || userRole === "superadmin") && (
        <Col>
          <button
            style={{ marginLeft: "3%" }}
            className="btn btn-danger"
            onClick={() => deletePosts()}
          >
            Obriši
          </button>
        </Col>
      )}
      {modalIsOpen && <ShareButtons id={post.id} />}
    </Container>
  );
}

export default PoslednjiPozdrav;
