import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

function ContactInformationPage() {
    return (
        <Container>
            <Row style={{marginTop: "20rem"}}>
                <div className="contact_information_wrapper text-center">
                    <div className="title">
                        <h1>Contact Information</h1>
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                        <ul>
                            <li>
                                Name: Daily Press doo
                            </li>
                            <li>
                                Registration number: 02255383
                            </li>
                            <li>
                                VAT number: 30/31-01515-3
                            </li>
                            <li>
                                Address: Trg Nezavisnosti bb, 81000 Podgorica, Montenegro
                            </li>
                            <li>
                                Customer support: <a href="mailto:upiti@vijesti.me">upiti@vijesti.me</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Row>
        </Container>
    );
}

export default ContactInformationPage;
