import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Formik, Field } from "formik";
import axios from "axios";
import Select from "react-select";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useParams } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { API_URL } from "../config";

const UpdateForm = ({ cities }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedIconUrl, setSelectedIconUrl] = useState();

  const [deathDate, setDeathDate] = useState();
  const [birthDate, setBirthDate] = useState();

  const { postId } = useParams();
  const [post, setPost] = useState({});

  useEffect(() => {
    fetchPost();
  }, []);

  const fetchPost = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/posts/${postId}`
      );
      setPost(response.data);
      setSelectedIconUrl(response.data.icon);
      setSelectedImages(
        response.data.images.reduce((p, c) => p + c).split(",")
      );
      setDeathDate(response.data.death_date);
      setBirthDate(response.data.birth_date);
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  Date.prototype.mmddyyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      this.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
    ].join("-");
  };

  const handleSubmit = async (values, { resetForm }) => {
    const uploadedImagePaths = [];

    // Upload new images and get their paths
    for (let i = 0; i < selectedImages.length; i++) {
      const image = selectedImages[i];

      if (image instanceof File) {
        try {
          const imgFormData = new FormData();
          imgFormData.append("image", image);

          const response = await axios.post(
            `${API_URL}/api/posts/upload_image/`,
            imgFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );

          const uploadedImagePath = response.data.path;
          uploadedImagePaths.push(uploadedImagePath);
        } catch (error) {
          console.error(error);
        }
      } else {
        // Image path is already available
        uploadedImagePaths.push(image);
      }
    }

    const data = {
      size: post.size,
      name: values.name,
      main_text: values.main_text,
      icon: selectedIconUrl,
      type: post.type,
      checkbox: post.type,
      header_text: values.header_text,
      text_top: values.text_top,
      text_bottom: values.text_bottom,
      images: uploadedImagePaths.join(","),
      color: post.color,
    };

    // Now you can send jsonData to your server using your preferred method (e.g., fetch, axios, etc.)

    try {
      const response = await axios.put(
        `${API_URL}/api/posts/${postId}/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      NotificationManager.success(
        `${post.type} uspješno ${post.type === "umrlica" ? "izmijenjena!" : "izminjenjen!"
        }`
      );
      // Reset form values
      resetForm();
      setTimeout(() => {
        window.location.href = "/user-profile";
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  let topTextLimit = 100;
  let nameLimit = 40;
  let mainTextLimit = 360;
  let bottomTextLimit = 120;

  if (post.size === 2 && post.type === "umrlica") {
    topTextLimit = 100;
    nameLimit = 50;
    mainTextLimit = 360;
    bottomTextLimit = 280;
  } else if (post.size === 2) {
    topTextLimit = 100;
    nameLimit = 40;
    mainTextLimit = 360;
    bottomTextLimit = 120;
  } else if (post.size === 3 && post.type === "umrlica") {
    topTextLimit = 100;
    nameLimit = 50;
    mainTextLimit = 320;
    bottomTextLimit = 280;
  } else if (post.size === 3) {
    topTextLimit = 100;
    nameLimit = 40;
    mainTextLimit = 600;
    bottomTextLimit = 170;
  } else if (post.size === 1 && post.type === "umrlica") {
    topTextLimit = 80;
    nameLimit = 50;
    mainTextLimit = 200;
    bottomTextLimit = 200;
  } else if (post.size === 1) {
    topTextLimit = 60;
    nameLimit = 40;
    mainTextLimit = 150;
    bottomTextLimit = 70;
  }

  const handleReplaceImage = (index) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      const newImageFile = event.target.files[0];
      const newSelectedImages = [...selectedImages];
      newSelectedImages[index] = newImageFile;

      setSelectedImages(newSelectedImages);
    };
    input.click();
  };

  const handleAddImage = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      const newImage = event.target.files[0];
      setSelectedImages([...selectedImages, newImage]);
    };
    input.click();
  };

  const handleDeleteImage = (index) => {
    const newSelectedImages = [...selectedImages];
    newSelectedImages.splice(index, 1);
    setSelectedImages(newSelectedImages);
  };
  const handleKeyDown = (e, values, type, setFieldValue) => {
    if (e.keyCode === 13) {
      setFieldValue(type, values[type] + "\n");
    }
  };
  return (
    <Formik
      initialValues={{
        name: post.name || "",
        text_top: post.text_top || "",
        main_text: post.main_text || "",
        icon: selectedIconUrl || "",
        images: selectedImages,
        size: post.size,
        text_bottom: post.text_bottom || "",
        color: post.color,
        header_text: post.header_text || "",
        city: post.city,
        death_date: post.death_date,
        birth_date: post.birth_date,
      }}
      enableReinitialize={true} // Add this line
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, setFieldValue, values, handleChange }) => (
        <Form
          className="FormaZaPopunjavanje-form"
          onSubmit={handleSubmit}
          style={{
            margin: "0 auto",
            marginTop: "10%",
            maxWidth: "500px",
            padding: "20px",

            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
            border: "1px solid gray",
            marginBottom: "2%",
          }}
        >
          <div>
            <p>Tip: {post.type}</p>
            <p>
              Velicina:{" "}
              {(post.size === 3 && "velika") ||
                (post.size === 2 && "srednja") ||
                (post.size === 1 && "mala") ||
                (post.size === 1 && "najmanja")}
            </p>

            <Row>
              <Col sm={6}>
                <Form.Group controlId="formName">
                  <Form.Label className="FormaZaPopunjavanje-label">
                    Ime i Prezime
                  </Form.Label>
                  <Form.Control
                    placeholder="Unesite ime i prezime..."
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    className="FormaZaPopunjavanje-input"
                    style={{ marginTop: "8px" }}
                    maxLength={50} // Set maximum length for the input
                  />
                  <span className="char-counter">
                    {values.name.length}/50 karaktera
                  </span>
                </Form.Group>
              </Col>
              {post.text_top !== "" && (
                <Col sm={6}>
                  <Form.Group controlId="formTopText">
                    <Form.Label className="FormaZaPopunjavanje-label">
                      Gornji tekst
                    </Form.Label>
                    <Form.Control
                      placeholder="Unesite tekst..."
                      name="text_top"
                      onChange={handleChange}
                      value={values.text_top}
                      className="FormaZaPopunjavanje-input"
                      style={{ marginTop: "8px" }}
                      maxLength={80} // Set maximum length for the input
                    />
                    <span className="char-counter">
                      {values.text_top.length}/80 karaktera
                    </span>
                  </Form.Group>
                </Col>
              )}
            </Row>
            {post.header_text !== "" && (
              <Col style={{ marginTop: "10px", paddingBottom: "10px" }}>
                <Form.Group controlId="formImageTopText">
                  <Form.Label className="FormaZaPopunjavanje-label">
                    Tekst iznad slike
                  </Form.Label>
                  <Form.Control
                    placeholder="Unesite tekst..."
                    name="header_text"
                    onChange={handleChange}
                    value={values.header_text}
                    className="FormaZaPopunjavanje-input"
                    style={{ marginTop: "8px" }}
                    maxLength={55} // Set maximum length for the input
                  />
                  <span className="char-counter">
                    {values.header_text.length}/55 karaktera
                  </span>
                </Form.Group>
              </Col>
            )}
            {!post.birth_date && (
              <Row>
                <div className="col-6">
                  <Form.Label>Datum rodjenja</Form.Label>
                  <DatePicker
                    name="birth_date"
                    onChange={setBirthDate}
                    value={birthDate}
                    className="FormaZaPopunjavanje-input"
                    style={{ marginTop: "8px" }}
                    format="dd/MM/y"
                  />
                </div>
                <Col>
                  <Form.Label>Datum smrti</Form.Label>
                  <DatePicker
                    name="death_date"
                    onChange={setDeathDate}
                    value={deathDate}
                    className="FormaZaPopunjavanje-input"
                    style={{ marginTop: "8px" }}
                    format="dd/MM/y"
                  />
                </Col>
              </Row>
            )}
            <Form.Group style={{ marginTop: "5%" }} controlId="opcije">
              <Form.Label>Grad:</Form.Label>
              <Field
                style={{ marginLeft: "2%", borderRadius: "8px", padding: "1%" }}
                name="city"
                as="select"
              >
                {cities.length !== 0 && (
                  <option defaultValue={"Izaberi grad"}>
                    Izaberi grad (nije obavezno)
                  </option>
                )}

                {cities.length === 0 && (
                  <option defaultValue={"Izaberi grad"}>
                    Gradovi se ocitavaju...
                  </option>
                )}
                {cities.map((city, index) => {
                  return (
                    <option key={index} value={city.id}>
                      {city.name}
                    </option>
                  );
                })}
              </Field>
            </Form.Group>

            <Form.Group controlId="formMainText">
              <Form.Label className="FormaZaPopunjavanje-label">
                Glavni tekst
              </Form.Label>
              <Form.Control
                placeholder="Unesite glavni tekst..."
                name="main_text"
                as="textarea"
                rows={4}
                onKeyDown={(e) =>
                  handleKeyDown(e, values, "main_text", setFieldValue)
                }
                onChange={handleChange}
                value={values.main_text}
                className="FormaZaPopunjavanje-textarea"
                style={{ marginTop: "8px" }}
                maxLength={mainTextLimit} // Set maximum length for the input
              />
              <span className="char-counter">
                {values.main_text.length}/{mainTextLimit}
              </span>
            </Form.Group>
            {post.text_bottom !== "" && (
              <Form.Group controlId="formBottomText">
                <Form.Label className="FormaZaPopunjavanje-label">
                  {post.type === "umrlica" ? "Ožalošćeni" : "Od"}:
                </Form.Label>
                <Form.Control
                  placeholder="Unesite tekst..."
                  name="text_bottom"
                  onKeyDown={(e) =>
                    handleKeyDown(e, values, "text_bottom", setFieldValue)
                  }
                  as="textarea"
                  rows={4}
                  onChange={handleChange}
                  value={values.text_bottom}
                  className="FormaZaPopunjavanje-textarea"
                  style={{ marginTop: "8px" }}
                  maxLength={bottomTextLimit}
                />
                <span className="char-counter">
                  {values.text_bottom.length}/{bottomTextLimit}
                </span>
              </Form.Group>
            )}
            {post.type === "umrlica" && post.size === 3 && (
              <Row style={{ paddingBottom: "2%" }}>
                <Col sm={6}>
                  <label>Uredi ikonicu</label>
                  <Select
                    options={[
                      {
                        value: "Bez ikonice",
                        label: "Bez ikonice",
                      },
                      {
                        value:
                          `${API_URL}/media/icons/icon1.png`,
                        label: (
                          <img
                            height={20}
                            width={20}
                            src={require("../assets/ikonice/icon1.png")}
                            alt="Icon 1"
                          />
                        ),
                      },
                      {
                        value:
                          `${API_URL}/media/icons/icon2.png`,
                        label: (
                          <img
                            height={20}
                            width={20}
                            src={require("../assets/ikonice/icon2.png")}
                            alt="Icon 2"
                          />
                        ),
                      },
                      {
                        value:
                          `${API_URL}/media/icons/icon3.png`,
                        label: (
                          <img
                            height={20}
                            width={20}
                            src={require("../assets/ikonice/icon3.png")}
                            alt="Icon 3"
                          />
                        ),
                      },
                      {
                        value:
                          `${API_URL}/media/icons/icon4.png`,
                        label: (
                          <img
                            height={20}
                            width={20}
                            src={require("../assets/ikonice/icon4.png")}
                            alt="Icon 3"
                          />
                        ),
                      },
                    ]}
                    onChange={(selectedOption) => {
                      setSelectedIconUrl(selectedOption.value);
                      setFieldValue("icon", selectedOption.value);
                    }}
                    value={
                      selectedIconUrl && selectedIconUrl !== "Bez ikonice"
                        ? {
                          value: selectedIconUrl,
                          label: (
                            <div>
                              <img
                                height={20}
                                width={20}
                                src={selectedIconUrl}
                                alt="Selected Icon"
                              />
                              <span style={{ marginLeft: "10px" }}>
                                Izabrana ikonica
                              </span>
                            </div>
                          ),
                        }
                        : null
                    }
                  />
                </Col>
              </Row>
            )}
            {post.type === "umrlica" && post.size === 2 && (
              <Row style={{ paddingBottom: "2%" }}>
                <Col sm={6}>
                  <label>Uredi ikonicu</label>
                  <Select
                    options={[
                      {
                        value: "Bez ikonice",
                        label: "Bez ikonice",
                      },
                      {
                        value:
                          `${API_URL}/media/icons/icon1.png`,
                        label: (
                          <img
                            height={20}
                            width={20}
                            src={require("../assets/ikonice/icon1.png")}
                            alt="Icon 1"
                          />
                        ),
                      },
                      {
                        value:
                          `${API_URL}/media/icons/icon2.png`,
                        label: (
                          <img
                            height={20}
                            width={20}
                            src={require("../assets/ikonice/icon2.png")}
                            alt="Icon 2"
                          />
                        ),
                      },
                      {
                        value:
                          `${API_URL}/media/icons/icon3.png`,
                        label: (
                          <img
                            height={20}
                            width={20}
                            src={require("../assets/ikonice/icon3.png")}
                            alt="Icon 3"
                          />
                        ),
                      },
                      {
                        value:
                          `${API_URL}/media/icons/icon4.png`,
                        label: (
                          <img
                            height={20}
                            width={20}
                            src={require("../assets/ikonice/icon4.png")}
                            alt="Icon 3"
                          />
                        ),
                      },
                    ]}
                    onChange={(selectedOption) => {
                      setSelectedIconUrl(selectedOption.value);
                      setFieldValue("icon", selectedOption.value);
                    }}
                    value={
                      selectedIconUrl && selectedIconUrl !== "Bez ikonice"
                        ? {
                          value: selectedIconUrl,
                          label: (
                            <div>
                              <img
                                height={20}
                                width={20}
                                src={selectedIconUrl}
                                alt="Selected Icon"
                              />
                              <span style={{ marginLeft: "10px" }}>
                                Izabrana ikonica
                              </span>
                            </div>
                          ),
                        }
                        : null
                    }
                  />
                </Col>
              </Row>
            )}
            <label>Uredi fotografije</label>
            <div
              style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}
            >
              {selectedImages.map((image, index) => (
                <div
                  key={index}
                  style={{ marginRight: "20px", marginBottom: "20px" }}
                >
                  <div style={{ position: "relative" }}>
                    {image instanceof File ? (
                      <img
                        src={URL.createObjectURL(image)} // Use createObjectURL to display local image
                        alt={`Izabrana slika ${index}`}
                        style={{
                          maxWidth: "120px",
                          maxHeight: "120px",
                          marginRight: "10px",
                        }}
                      />
                    ) : (
                      <img
                        src={
                          image.startsWith("http")
                            ? image
                            : `${API_URL}/` +
                            image
                        }
                        alt={`Izabrana slika ${index}`}
                        style={{
                          maxWidth: "120px",
                          maxHeight: "120px",
                          marginRight: "10px",
                        }}
                      />
                    )}
                    <div style={{ display: "flex", marginTop: "5px" }}>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDeleteImage(index)}
                      >
                        X
                      </Button>
                      <Button
                        size="sm"
                        style={{ marginLeft: "5px" }}
                        onClick={() => handleReplaceImage(index)}
                      >
                        Promijeni
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              {selectedImages.length < 3 && (
                <div style={{ marginRight: "20px", marginBottom: "20px" }}>
                  <Button variant="success" size="sm" onClick={handleAddImage}>
                    Dodaj
                  </Button>
                </div>
              )}
            </div>

            {post.type === "Umrlica" && (
              <Col>
                <Form.Group controlId="formColor">
                  <Form.Label className="FormaZaPopunjavanje-label">
                    Izaberi boju
                  </Form.Label>
                  <Form.Control
                    name="color"
                    as="select"
                    onChange={handleChange}
                    value={values.color}
                    className="FormaZaPopunjavanje-select"
                    style={{ marginTop: "8px" }}
                  >
                    <option value="">Izaberi boju</option>
                    <option
                      style={{ backgroundColor: "black", color: "white" }}
                      value="black"
                    >
                      Crna
                    </option>
                    <option
                      style={{ backgroundColor: "#0d51d9", color: "white" }}
                      value="blue"
                    >
                      Plava
                    </option>
                    <option
                      style={{ backgroundColor: "green", color: "white" }}
                      value="green"
                    >
                      Zelena
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
            )}
            <Button
              className="FormaZaPopunjavanje-submit"
              variant="primary"
              type="submit"
              style={{ marginTop: "16px" }}
            >
              Izmijeni objavu
            </Button>
          </div>

          <NotificationContainer />
        </Form>
      )}
    </Formik>
  );
};

export default UpdateForm;
