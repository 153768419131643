import React from "react";

const Cookies = () => {
  return (
    <div className="container" style={{ paddingTop: "140px" }}>
      <h2 className="text-center">Kolačići i zaštita privatnosti</h2>
      <p className="mt-3">
        Portal "umrlice.vijesti.me" je u obavezi da poštuje anonimnost i
        privatnost svojih korisnika. U skladu sa zakonom, portal može da
        prikuplja određene podatke o korisnicima dobijene tokom korišćenja
        portala, poput e-mail adrese, ime i prezime, podatke o računaru i
        internet provajderu. Portal se obavezuje da će čuvati privatnost, osim u
        slučaju teškog kršenja pravila portala ili nezakonitih aktivnosti
        korisnika. Portal "umrlice.vijesti.me" zadržava pravo da bez najave
        promijeni ili modifikuje pravila i uslove korišćenja portala. Portal
        "umrlice.vijesti.me" će o promjenama i dopunama pravila portala
        korisnike informisati objavljivanjem na portalu Vijesti.me ili putem
        e-maila. Portal takođe zadržava pravo da plasira sadržaj koji kreira
        putem email newslettera korisnicima koji su registrovani na sajtu.{" "}
      </p>
      <h4 className="text-center mt-3">Politika kolačića</h4>
      <p className="mt-3">
        Ova Politika kolačića („Politika“) odnosi se na internet stranicu kojom
        upravlja privredno društvo Daily Press d.o.o
        (https://www.umrlice.vijesti.me/ ) ili je na drugi način obezbeđuje.
        Pristupom i korišćenjem internet stranice
        https://www.umrlice.vijesti.me/ pristajete na upotrebu kolačića, na
        način opisan u ovoj Politici i u skladu sa izborima koje sami napravite,
        a za koje se smatrate isključivo odgovornim. Privredno društvo Daily
        Press d.o.o, kao vlasnik internet stranice
        https://www.umrlice.vijesti.me/, izričito zadržava pravo da povremeno
        menja ovu Politiku. Ako postoje bilo kakve značajne izmene u ovoj
        Politici, korisnici će biti obavešteni putem internet stranice
        https://www.umrlice.vijesti.me/. Preporučujemo vam da periodično
        pregledate ovu stranicu kako biste saznali najnovije informacije o
        Politici kolačića. U svakom trenutku možete potpuno da odbijete
        prihvatanje kolačića korišćenjem podešavanja vašeg internet
        pretraživača. Vašim daljim korišćenjem internet stranice
        https://www.umrlice.vijesti.me/, kao i bilo kojih njenih podstranica,
        servisa i pripadajućih delova, smatraće se da ste dali vašu saglasnost,
        da prihvatate i da ste se upoznali sa pravilima ove Politike. Portal
        https://www.umrlice.vijesti.me/ ne koristi kolačiće za bilo kakve
        promotivne, svrhe praćenja aktivnosti korisnika ili druge. Portal
        https://www.umrlice.vijesti.me/ koristi samo software za obradu podataka
        Google Analytics kako bi pratio metrike vezane za sajt, a nevezane za
        korisničke lične podatke.
      </p>
      <h4 className="text-center mt-3">
        Kako uključiti i isključiti kolačiće uz pomoć internet pretraživača
        Google Chrome
      </h4>
      <p className="mt-3">
        Kliknite ikonicu ključa na traci sa alatkama pretraživača >> Izaberite
        Podešavanja > Kliknite „Prikaži napredna podešavanja“> U odjeljku
        „Privatnost“ kliknite na dugme „Podešavanja sadržaja“. Da biste
        omogućili kolačiće u odeljku „Kolačići“, izaberite „Dopusti postavljanje
        lokalnih podataka“, to će omogućiti i kolačiće prve i treće strane. Da
        biste dozvolili samo kolačiće prve strane izaberite „Blokiraj sve
        kolačiće treće strane bez izuzetka“. Da biste onemogućili kolačiće, u
        odeljku „Kolačići“ izaberite „Blokiraj veb lokacije u podešavanju bilo
        kakvih podataka“. Imajte na umu da u Chrome-u postoje različiti nivoi
        omogućavanja I onemogućavanja kolačića. Više informacija o ostalim
        podešavanjima kolačića koji se nude u Chrome-u potražite na sljedećoj
        stranici od Google-a: http://support.google.com/
        chrome/bin/answer.pi?hl=sr&ansver=95647
      </p>
      <h4 className="text-center mt-3">
        Microsoft Internet Explorer 6.0, 7.0, 8.0
      </h4>
      <p className="mt-3">
        Kliknite na „Alatke“ na vrhu prozora pregledača i odaberite „Internet
        Options“ > U prozoru opcija idite na karticu „Privacy“. Da biste
        omogućili kolačiće: Podesite klizač na „Srednje“ ili ispod. Da biste
        onemogućili kolačiće: Pomerite klizač na vrh da biste blokirali sve
        kolačiće. Imajte u vidu da u Explorer-u postoje razni nivoi omogućavanja
        i onemogućavanja kolačića. Za više informacija o ostalim podešavanjima
        kolačića u programu Internet Explorer pogledajte sledeću stranicu
        kompanije Microsoft:
        http://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies.
      </p>
      <h4 className="text-center mt-3">Microsoft Edge</h4>
      <p className="mt-3">
        Kliknite na dugme “Više radnji” na alatnoj traci i izaberite Podešavanja
        > Tražite Prikaži napredna podešavanja i kliknite na njih > U odeljku
        „Kolačići“ izaberite “Ne blokiraj kolačiće (podrazumevano)”, Blokirajte
        samo kolačiće treće strane > Restart Edge.
      </p>
      <h4 className="text-center mt-3">Mozilla Firefox</h4>
      <p className="mt-3">
        Kliknite na „Alatke“ u meniju pregledača i izaberite „Opcije“ >>
        Izaberite panel privatnosti. Da biste omogućili kolačiće: Označite
        „Prihvati kolačiće za veb lokacije“ Da biste onemogućili kolačiće:
        Poništite oznaku „Prihvati kolačiće za veb lokacije“. Imajte u vidu da
        postoje različiti nivoi omogućavanja i onemogućavanja kolačića u
        Firefoxu. Za više informacija pogledajte sledeću stranicu iz Mozilla:
        http://support.mozilla.org/en-US/kb/Enabling% 20and%
        20disabling%20cookies
      </p>
      <h4 className="text-center mt-3">Opera</h4>
      <p className="mt-3">
        Kliknite na „Podešavanje“ u meniju pregledača i izaberite „Podešavanja“
        >> Izaberite „Brze postavke“. Da biste omogućili kolačiće: potvrdite
        „Omogući kolačiće“. Da biste onemogućili kolačiće: poništite izbor
        „Omogući kolačiće“. Imajte na umu da u okviru programa Opera postoje
        različiti nivoi omogućavanja i onemogućavanja kolačića. Za više
        informacija o ostalim podešavanjima kolačića koji se nude u Operi,
        pogledajte sledeću stranicu programa Opere:
        http://www.opera.com/browser/tutorials/security/privacy.
      </p>
      <h4 className="text-center mt-3">Safari na OSX</h4>
      <p className="mt-3">
        Kliknite na „Safari“ na traci sa menijima i odaberite opciju
        „Preferences“ > Kliknite na „Security“. Da biste omogućili kolačiće: U
        odeljku „Accept cookies“ izaberite „Only from site you navigate to“. Da
        biste onemogućili kolačiće: U delu „Prihvati kolačiće“ odaberite „Nikad“
        Imajte u vidu da u Safariju postoje razni nivoi omogućavanja i
        onemogućavanja kolačića. Za više informacija o ostalim podešavanjima
        kolačića ponuđenih na Safariju, pogledajte sledeću stranicu kompanije
        Apple: https://support.apple.com/en-us/guide/safari/sfri11471/mac.
      </p>
      <h4 className="text-center mt-3">Ostali internet pretraživači</h4>
      <p className="mt-3">
        Za kontrolu kolačića, pogledajte dostupne opcije ili “Pomoć” u okviru
        svakog pretraživača.
      </p>
    </div>
  );
};

export default Cookies;
