import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Modal, Container } from "react-bootstrap";
import { Formik } from "formik";
import axios from "axios";

import { Carousel } from "react-responsive-carousel";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import SubmitFormPreview from "./SubmitFormPreview";

import { API_URL } from "../config";

let top_text_limit;
let main_text_limit;
let bottom_text_limit;
const RedirectForm = () => {
  const [postData, setPostData] = useState("");
  const postId = parseInt(localStorage.getItem("postId"));
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [currentPostSize, setCurrentPostSize] = useState("2");
  const [images, setImages] = useState([]);
  useEffect(() => {
    const postId = localStorage.getItem("postId");
    fetchPosts(postId);
  }, []);

  const handleShowPreviewModal = () => {
    setShowPreviewModal(true);
  };

  const handleClosePreviewModal = () => {
    setShowPreviewModal(false);
  };

  const handleSubmit = async (values, { resetForm }) => {
    let formData = new FormData();
    formData.append("name", postData.name);
    formData.append("text_top", values.top_text);
    formData.append("main_text", values.main_text);
    formData.append("icon", values.icon);
    formData.append("images", images.reduce((p, c) => p + c).split(","));
    formData.append("type", "poslednji_pozdrav");
    formData.append("text_bottom", values.bottom_text);
    if (formData.size === "4") {
      formData.append("size", "4");
    } else {
      formData.append("size", values.size);
    }
    formData.append("size", values.size);
    formData.append("parent", postId);

    try {
      const response = await axios.post(
        `${API_URL}/api/posts/?parent=${postId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      // Display success notification
      NotificationManager.success("Poslednji pozdrav uspješno predat!");
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
      // Reset form values
      resetForm(); // Call resetForm function
    } catch (error) {
      console.error(error);
      // Display error notification
      NotificationManager.error(
        "Greška prilikom predavanja poslednjeg pozdrava!"
      );
    }
  };

  const fetchPosts = async (postId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/posts/${postId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      setPostData(response.data);
      setImages(response.data.images);
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };
  useEffect(() => {
    if (currentPostSize === "1") {
      top_text_limit = 80;
      main_text_limit = 120;
      bottom_text_limit = 70;
    } else if (currentPostSize === "2") {
      top_text_limit = 80;
      main_text_limit = 360;
      bottom_text_limit = 120;
    } else if (currentPostSize === "3") {
      top_text_limit = 80;
      main_text_limit = 600;
      bottom_text_limit = 170;
    }
  }, [currentPostSize]);

  return (
    <Formik
      initialValues={{
        name: postData.name,
        top_text: "",
        main_text: "",
        icon: "",
        image: `${API_URL}/` + postData.image,
        type: "poslednji_pozdrav",
        size: "2",
        bottom_text: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, setFieldValue, values, handleChange, resetForm }) => (
        <Form
          className="FormaZaPopunjavanje-form"
          onSubmit={handleSubmit}
          style={{
            margin: "0 auto",
            marginTop: "3%",
            maxWidth: "500px",
            padding: "20px",
            marginTop: "140px",
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
          }}
        >
          <div>
            <Row className="mb-3">
              <Row>
                <Col>
                  {images.length !== 0 && (
                    <Carousel
                      className="form-slider"
                      showArrows={false}
                      infiniteLoop={true}
                      autoPlay={true}
                      interval={3000}
                    >
                      {images
                        .reduce((p, c) => p + c)
                        .split(",")
                        .map((imagePath, index) => (
                          <div key={index}>
                            <img
                              style={{
                                width: "100px",
                                height: "100px",
                                border: "3px solid black",
                              }}
                              src={
                                `${API_URL}/` +
                                imagePath
                              }
                              alt={`Image ${index + 1}`}
                            />
                          </div>
                        ))}
                    </Carousel>
                  )}
                </Col>
                <Col>
                  <h5 className="mb-0">{postData.name}</h5>
                  <p className="mb-0">Tip: Poslednji pozdrav</p>
                </Col>
              </Row>
            </Row>
            {currentPostSize === "4" && (
              <span style={{ textAlign: "center" }}>
                Najmanji poslednji pozdrav sadrži samo ime
              </span>
            )}
            <hr className="my-4" />
            {currentPostSize !== "4" && (
              <Row>
                <Col sm={6}>
                  <Form.Group controlId="formTopText">
                    <Form.Label className="FormaZaPopunjavanje-label">
                      Gornji tekst
                    </Form.Label>
                    <Form.Control
                      name="top_text"
                      onChange={handleChange}
                      value={values.top_text}
                      className="FormaZaPopunjavanje-input"
                      placeholder="Unesite gornji tekst..."
                      maxLength={top_text_limit}
                      required
                    />
                    <p style={{ textAlign: "right" }}>
                      {values.top_text.length}/{top_text_limit}
                    </p>
                  </Form.Group>
                </Col>
              </Row>
            )}
            {currentPostSize !== "4" && (
              <Form.Group controlId="formMainText" className="mt-3">
                <Form.Label className="FormaZaPopunjavanje-label">
                  Glavni tekst
                </Form.Label>
                <Form.Control
                  name="main_text"
                  as="textarea"
                  rows={4}
                  onChange={handleChange}
                  value={values.main_text}
                  className="FormaZaPopunjavanje-textarea"
                  placeholder="Unesite glavni tekst..."
                  maxLength={main_text_limit}
                  required
                />
                <p style={{ textAlign: "right" }}>
                  {values.main_text.length}/{main_text_limit}
                </p>
              </Form.Group>
            )}
            {currentPostSize !== "4" && (
              <Form.Group controlId="formBottomText" className="mt-3">
                <Form.Label className="FormaZaPopunjavanje-label">
                  Ožalošćeni
                </Form.Label>
                <Form.Control
                  name="bottom_text"
                  as="textarea"
                  rows={4}
                  onChange={handleChange}
                  value={values.bottom_text}
                  className="FormaZaPopunjavanje-textarea"
                  placeholder="Navedite ožalošćene..."
                  maxLength={bottom_text_limit}
                  required
                />{" "}
                <p style={{ textAlign: "right" }}>
                  {values.bottom_text.length}/{bottom_text_limit}
                </p>
              </Form.Group>
            )}

            {images.length !== 0 && (
              <Form.Group controlId="formVelicina" className="mt-3">
                <div>
                  <Form.Label className="FormaZaPopunjavanje-label">
                    Veličina
                  </Form.Label>
                  <Form.Check
                    style={{ paddingLeft: "0", marginLeft: "0" }}
                    name="size"
                    type="radio"
                    label="Najmanja"
                    value="4"
                    onChange={(event) => {
                      setCurrentPostSize(event.target.value);
                      resetForm();
                      setFieldValue("size", event.target.value);
                    }}
                    checked={values.size === "4"}
                    className="FormaZaPopunjavanje-checkbox-label"
                  />
                  <Form.Check
                    style={{ paddingLeft: "0", marginLeft: "0" }}
                    name="size"
                    type="radio"
                    label="Mala"
                    value="1"
                    onChange={(event) => {
                      setCurrentPostSize(event.target.value);
                      resetForm();
                      setFieldValue("size", event.target.value);
                    }}
                    checked={values.size === "1"}
                    className="FormaZaPopunjavanje-checkbox-label"
                  />
                  <Form.Check
                    style={{ paddingLeft: "0" }}
                    name="size"
                    type="radio"
                    label="Srednja"
                    value="2"
                    onChange={(event) => {
                      setCurrentPostSize(event.target.value);
                      resetForm();
                      setFieldValue("size", event.target.value);
                    }}
                    checked={values.size === "2"}
                    className="FormaZaPopunjavanje-checkbox-label"
                  />
                  <Form.Check
                    style={{ paddingLeft: "0" }}
                    name="size"
                    type="radio"
                    label="Velika"
                    value="3"
                    onChange={(event) => {
                      setCurrentPostSize(event.target.value);
                      resetForm();
                      setFieldValue("size", event.target.value);
                    }}
                    checked={values.size === "3"}
                    className="FormaZaPopunjavanje-checkbox-label"
                  />
                  <p className="mt-2 text-secondary">
                    Izaberite veličinu kako biste pogledali primjer
                  </p>{" "}
                </div>
              </Form.Group>
            )}

            <Button
              className="FormaZaPopunjavanje-submit mt-4"
              variant="primary"
              type="submit"
            >
              Predaj
            </Button>
            <Button
              variant="primary"
              className="mt-4"
              style={{ float: "right" }}
              onClick={handleShowPreviewModal}
            >
              Pogledaj
            </Button>
          </div>
          {values.size === "4" && (
            <Modal
              show={showPreviewModal} // Use showPreviewModal instead of handleShowPreviewModal
              onHide={handleClosePreviewModal} // Use onHide instead of hide
              contentlabel="Post Modal"
              size="xl"
              style={{ padding: "0px" }}
            >
              <Container style={{ paddingTop: "20px" }} className="px-3">
                <SubmitFormPreview
                  type={"Poslednji pozdrav"}
                  size={"Najmanja"}
                  name={postData.name}
                  icon={values.icon}
                  image={images.reduce((p, c) => p + c).split(",")}
                  main_text={values.main_text}
                  bottom_text={values.bottom_text}
                  headerText={values.header_text}
                  text_top={values.top_text}
                />

                <Button
                  variant="secondary"
                  className="mt-4 float-right"
                  onClick={handleClosePreviewModal} // Call handleClosePreviewModal on button click
                >
                  Zatvori
                </Button>
              </Container>
            </Modal>
          )}
          {values.size === "3" && (
            <Modal
              show={showPreviewModal} // Use showPreviewModal instead of handleShowPreviewModal
              onHide={handleClosePreviewModal} // Use onHide instead of hide
              contentlabel="Post Modal"
              size="xl"
              style={{ padding: "0px" }}
            >
              <Container style={{ paddingTop: "20px" }} className="px-3">
                <SubmitFormPreview
                  type={"Poslednji pozdrav"}
                  size={"Velika"}
                  name={values.name}
                  icon={values.icon}
                  image={images.reduce((p, c) => p + c).split(",")}
                  main_text={values.main_text}
                  bottom_text={values.bottom_text}
                  headerText={values.header_text}
                  text_top={values.top_text}
                />

                <Button
                  variant="secondary"
                  className="mt-4 float-right"
                  onClick={handleClosePreviewModal} // Call handleClosePreviewModal on button click
                >
                  Zatvori
                </Button>
              </Container>
            </Modal>
          )}
          {values.size === "2" && postData && (
            <Modal
              show={showPreviewModal} // Use showPreviewModal instead of handleShowPreviewModal
              onHide={handleClosePreviewModal} // Use onHide instead of hide
              contentlabel="Post Modal"
              size="xl"
              style={{ padding: "0px" }}
            >
              <Container lg={12} style={{ paddingTop: "20px" }}>
                <SubmitFormPreview
                  type={"Poslednji pozdrav"}
                  size={"Srednja"}
                  name={values.name}
                  icon={values.icon}
                  image={images.reduce((p, c) => p + c).split(",")}
                  main_text={values.main_text}
                  bottom_text={values.bottom_text}
                  headerText={values.header_text}
                  text_top={values.top_text}
                />
                <Button
                  variant="secondary"
                  className="mt-4 float-right"
                  onClick={handleClosePreviewModal} // Call handleClosePreviewModal on button click
                >
                  Zatvori
                </Button>
              </Container>
            </Modal>
          )}
          {values.size === "1" && (
            <Modal
              show={showPreviewModal} // Use showPreviewModal instead of handleShowPreviewModal
              onHide={handleClosePreviewModal} // Use onHide instead of hide
              contentlabel="Post Modal"
              size="xl"
              style={{ padding: "0px" }}
            >
              <Container style={{ paddingTop: "20px" }} className="px-3">
                <SubmitFormPreview
                  type={"Poslednji pozdrav"}
                  size={"Mala"}
                  name={values.name}
                  icon={values.icon}
                  image={images.reduce((p, c) => p + c).split(",")}
                  main_text={values.main_text}
                  bottom_text={values.bottom_text}
                  headerText={values.header_text}
                  text_top={values.top_text}
                />

                <Button
                  variant="secondary"
                  className="mt-4 float-right"
                  onClick={handleClosePreviewModal} // Call handleClosePreviewModal on button click
                >
                  Zatvori
                </Button>
              </Container>
            </Modal>
          )}
          <NotificationContainer />
        </Form>
      )}
    </Formik>
  );
};

export default RedirectForm;
