import React from "react";

const TraditionAndPractices = () => {
  return (
    <div className="container" style={{ paddingTop: "140px" }}>
      {" "}
      <h2 className="text-center">Tradicija i običaji</h2>
      <h4 className="mt-5">Pravoslavna vjeroispovijest</h4>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Obavijest o smrti
      </p>
      <p className="mt-3">
        Kada smrt nastupi, članovi porodice preminulog prvo obavještavaju
        rodbinu i bliske prijatelje, a zatim se obavijest proširuje i na ostale
        poznanike. Uobičajeno je da bliski srodnici odmah dođu u dom pokojnika
        kako bi pružili podršku porodicai umrlog. Oni koji su u mogućnosti,
        često odmah pružaju financijsku podršku porodicai, bilo kao zajam ili
        dar, dok se ne obavi sprovod. Smrt dolazi neočekivano, stoga je podrška
        prijatelja i porodicai važna. Svećenik se unaprijed informira o datumu,
        mjestu i vremenu sprovoda. Pokojnik se odijeva u pripremljenu odjeću,
        obično u najbolje što je imao, muškarci se briju, i postavlja u lijes u
        koji se takođe stavlja odjeća koju je pokojnik nosio za života. U znak
        žalosti, porodica pokojnika počinje nositi crninu. Žene nose crni šal, a
        muškarci crnu košulju ili flor. Crnina se nosi četrdeset dana, šest
        mjeseci ili godinu dana, i tokom tog vremena porodica se suzdržava od
        sudjelovanja u zabavama i ne pjeva. Bitno je istaknuti da se, bez obzira
        na smrti u porodicai, uvijek obilježava krsna slava kao i inače. tokom
        godine dana nakon smrti, porodica se suzdržava od organiziranja zabava,
        vjenčanja ili sličnih događanja.
      </p>
      <p className="mt-3">
        U svim tim slučajevima, i u vezi sa svim običajima oko sprovoda,
        preporučuje se savjetovanje sa svećenikom jer se običaji mogu
        razlikovati u različitim regijama.
      </p>{" "}
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Izjava saučešća
      </p>
      <p className="mt-3">
        Prije sprovoda, rodbina, bliski prijatelji, kolege s posla i oni koji su
        provodili puno vremena s pokojnikom dolaze u dom kako bi izrazili sućut
        porodicai. Oni koji ne mogu doći, kao i oni iz šireg kruga prijatelja,
        dolaze na mjesto sprovoda i tu, prije početka službe, izražavaju sućut
        porodicai. Porodica može primiti sućut kod kuće ili u kapeli na groblju.
        Oni koji izražavaju sućut naklanjaju se nad lijesom, rukuju se prvo sa
        najbližim ženskim članovima porodicai pokojnika koji stoje u kapeli kod
        glave pokojnika i s najbližim muškim članovima porodicai koji stoje
        ispred kapele, izgovarajući riječi sućuti.
      </p>{" "}
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Sprovodni obred
      </p>
      <p className="mt-3">
        Za sprovodni obred priprema se kuhana pšenica, boca vina i boca
        pomiješanog vina i ulja. Svećenik dolazi u dogovoreno vrijeme i drži
        opelo. Nakon opela, svećenik poziva na posljednji oproštaj. Nakon
        oproštaja, lijes se zatvara i formira se sprovodna povorka koju predvodi
        krst, iza kojeg idu mladi noseći cvijeće, zatim lijes s pokojnikom, iza
        lijesa svećenik pjeva posmrtne pjesme, a za svećenikom ide porodica i
        ostali prisutni.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Sahranjivanje
      </p>
      <p className="mt-3">
        Sahranjivanje se vrši na groblju. Na grobu se obavlja kratka molitva,
        ponekad prijatelj pokojnika izgovori nekoliko riječ i u znak sjećanja na
        pokojnika, nakon čega se lijes spušta u grob. Prilikom toga, običaj je
        prekrižiti se, baciti šaku zemlje u grob uz riječi: "Neka mu je laka
        zemlja". U nekim područjima, osoba koja je u povorci nosila krst (na
        čelu povorke) prva odlazi s groblja jer se vjeruje da smrt ostaje na
        groblju, a radost se vraća u dom porodicai pokojnika.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Povratak kući
      </p>
      <p className="mt-3">
        Kod Pravoslavaca je običaj da se nakon sahrane ide u kuću pokojnika. Taj
        običaj se održao i danas. Porodica poziva sve prisutne da "svrate" kući,
        a na poziv se odazivaju kumovi i najbliži prijatelji. Ostali dolaze
        prema svojim mogućnostima.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Običaji vezani za pomen
      </p>
      <p className="mt-3">
        Pomeni (Parastosi) obuhvaćaju pojedinačne pomenove mrtvih koji se vrše
        nakon smrti: četrdeseti dan, nakon pola godine i nakon godinu dana.
      </p>
      <h4 className="mt-5">Katolička vjeroispovijest</h4>
      <p className="mt-3">
        Za katolike, smrt se doživljava kao završni susret s Bogom. Unatoč tuzi
        koju smrtni rastanak donosi, vjeruje se u nadu ponovnog susreta s
        voljenima kod Boga.
      </p>
      <p className="mt-3">
        Tradicija je održavanje bdijenja uz pokojnikov odar, što se smatra činom
        pobožnosti i molitve za pokojnikovu dušu. Bdijenje se može održati u
        kapeli, crkvi ili u porodicaskom domu, gdje rodbina i prijatelji donose
        cvijeće i pale svijeće u znak poštovanja.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Obred sahrane
      </p>
      <p className="mt-3">
        Sahrana u katoličkoj crkvi obuhvaća obrede koji se sastoje od tri
        dijela. Prvi dio obavlja se kod pokojnika gdje svećenik sveti vodom
        pokojnika i moli za oprost grijeha. Drugi dio uključuje molitvu pred
        svima prisutnima i čitanje odlomka iz Evanđelja, nakon čega kreće
        povorka prema groblju. U trećem dijelu, nad grobom, svećenik
        blagoslivlja mjesto posljednjeg počinka vjerujući da je to mjesto sad
        pod Božjom zaštitom.
      </p>
      <p className="mt-3">
        Katolička Crkva dopušta autopsiju i kremaciju pod određenim uslovima, a
        porodica izražava žalost kroz odijevanje i ponašanje, suzdržavajući se
        od zabave i veselja.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Običaji nakon sahrane
      </p>
      <p className="mt-3">
        Vjeruje se da molitve pomažu dušama pokojnika, a Sveta misa se smatra
        najvišim oblikom molitve. Svećenik služi Svete mise zadušnice, koje
        članovi porodicai često naručuju za svoje pokojne. Mise za pokojne služe
        se i osmi i trideseti dan nakon smrti, te kroz posebne molitve i mise
        tokom prvog mjeseca nakon smrti, podržavajući duše pokojnika na njihovom
        putu.
      </p>
      <h4 className="mt-5">Islamska vjeroispovijest</h4>
      <p className="mt-3">
        Za muslimane, smrt predstavlja realnost koja se odlikuje veličanstvenom
        mudrošću i jednostavnošću, a ne katastrofom. Smrt se vidi kao vrsta
        prelaska, razdvajanje duše od tijela i njezino usmjeravanje u 'dom prvog
        nagrađivanja ili kažnjavanja'. Smrt ne označava kraj života, već
        promjenu stanja, svojevrsno novo rođenje.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Žalovanje za preminulim
      </p>
      <p className="mt-3">
        U trenutku smrti muslimana, naricanje, kuknjava i preglasno plakanje
        nisu dopušteni. Žalost bi trebala biti izražena samo kroz stanje srca i
        osjećaja, bez spoljašnjihih znakova. Žalovanje, barem formalni dio, kod
        muslimana traje tri dana i tri noći, dok žalovanje udovice traje četiri
        mjeseca i deset dana. Preporučljivo je posjetiti porodica preminulog i
        izraziti sućut, usmeno ili pismeno, naglašavajući neizbježnost smrti,
        moleći za oprost grijeha preminulog i raj za njega, te strpljenje i
        blagostanje za njegovu porodica i rodbinu.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Priprema i pokop
      </p>
      <p className="mt-3">
        Obaveza kupanja preminule osobe i njezino omatanje u ćefine spada među
        vjerske dužnosti pri pokopu. Pripreme za pokop ne bi trebalo odgađati
        bez opravdanog razloga.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Dženaza-namaz i ispraćaj
      </p>
      <p className="mt-3">
        Nakon što je porodica obaviještena o smrti, a preminula osoba
        pripremljena prema vjerskim propisima, obavlja se dženaza-namaz.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Nošenje i ispraćaj
      </p>
      <p className="mt-3">
        Dženaze su se tradicionalno nosile na rukama do džamije i groblja, ali u
        gradovima se sada koriste prilagođena vozila. Tradicija nošenja dženaze
        podrazumijeva da pratitelji nose dženazu najmanje četrdeset koraka. Svi
        prisutni prate dženazu u tišini i skrušenosti, obavljajući zikr.
        Prisutni ne bi smjeli sjediti dok dženaza nije položena na zemlju.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Pokop
      </p>
      <p className="mt-3">
        Uobičajeno je da član porodicai, ili neka pobožna osoba, spusti
        preminulog u kabur, okrenutog prema Kibli, dok se recitira određena
        molitva. Nakon pokopa, prisutni sudjeluju u zakopavanju, a nakon toga se
        čita Kur'an i moli za pokojnika.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Posjeta grobu
      </p>
      <p className="mt-3">
        Posjeta grobovima je stara praksa koju preporučuje islam kako bi se
        iskazalo poštovanje prema preminulima. Prilikom posjeta groblju,
        posjetitelji bi trebali biti poštovateljski i izbjegavati gaziti ili
        sjediti na grobovima. Vjeruje se da su preminuli svjesni posjeta i da im
        to donosi radost.
      </p>{" "}
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Nakon pokopa
      </p>
      <p className="mt-3">
        Imam ili pobožna osoba može iskoristiti priliku nakon dženaze da uputi
        riječi prisutnima, umjesto tradicionalnog posmrtnog govora, podsjećajući
        na važne pouke i vrijednosti.
      </p>
    </div>
  );
};

export default TraditionAndPractices;
