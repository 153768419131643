import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../config";

import ShareButtons from "./ShareButtons";
function QuarterPomen({ post, modalIsOpen }) {
  const [counter, setCounter] = useState(post.counter);
  const [imageSrc, setImageSrc] = useState(require("../assets/candle.png"));
  const deletePosts = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(
        `${API_URL}/api/posts/${post.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const handleCandleClick = () => {
    // Toggle between two image sources
    if (imageSrc === require("../assets/candle.png")) {
      setImageSrc(require("../assets/candleColor.png"));
    } else {
      setImageSrc(require("../assets/candle.png"));
    }
    increaseCounter();
  };

  const increaseCounter = async () => {
    try {
      setCounter(post.counter + 1);
      await axios.post(
        `${API_URL}/api/posts/${post.id}/counter/up/`,
        {}
      );
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };
  const userRole = localStorage.getItem("userRole");
  return (
    <Container
      data-aos="fade-up"
      style={{ border: "5px double black", minHeight: "147.5px" }}
      fluid
      className="post"
    >
      <Row lg={12} md={12} sm={12}>
        {" "}
        <span
          style={{
            fontStyle: "italic",
            border: "none",
            borderTop: "none",
            textAlign: "right",
            backgroundColor: "white",
            color: "gray",
            fontSize: "smaller",
            paddingRight: "0.5vh",
          }}
        >
          {post.type === "poslednji_pozdrav" ? "poslednji pozdrav" : post.type}
        </span>
        <h4
          style={{
            fontSize: "20px",
            padding: "40px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {post.name}
        </h4>
        {modalIsOpen && <p style={{ textAlign: "left" }}>{post.main_text}</p>}
        {modalIsOpen && (
          <Row>
            {" "}
            <span style={{ textAlign: "center", fontStyle: "italic" }}>
              Upali svijeću
            </span>
          </Row>
        )}
        {modalIsOpen && (
          <Row>
            {" "}
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                alignItems: "center",
                borderRadius: "100%",
              }}
              onClick={handleCandleClick}
            >
              <img src={imageSrc} alt="Image" />
            </button>
          </Row>
        )}
        {modalIsOpen && (
          <Row style={{ paddingBottom: "0" }}>
            {" "}
            <span style={{ textAlign: "center" }}>{counter}</span>
          </Row>
        )}
        {modalIsOpen && <p style={{ width: "100%" }}>{post.text_bottom}</p>}
        {modalIsOpen && (userRole === "admin" || userRole === "superadmin") && (
          <Col>
            <button
              style={{ marginBottom: "2%" }}
              className="btn btn-danger"
              onClick={() => deletePosts()}
            >
              Obriši
            </button>
          </Col>
        )}
      </Row>
      {modalIsOpen && <ShareButtons id={post.id} />}
    </Container>
  );
}

export default QuarterPomen;
