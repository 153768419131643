import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
const EditPrices = () => {
  const [newPrices, setNewPrices] = useState([]);

  function getPrices() {
    const apiUrl = "https://api.umrlice.vijesti.me/api/prices/";
    const accessToken = localStorage.getItem("accessToken");

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    axios
      .get(apiUrl, { headers })
      .then((response) => {
        setNewPrices(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function updatePrice(id, newPriceSingle, newPriceMultiple, type, size) {
    const apiUrl = `https://api.umrlice.vijesti.me/api/prices/${id}/`;
    const accessToken = localStorage.getItem("accessToken");

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    axios
      .put(
        apiUrl,
        {
          type: type,
          size: size,
          price_single: newPriceSingle,
          price_multiple: newPriceMultiple,
        },
        { headers }
      )
      .then((response) => {
        // You can handle the response if needed.
        console.log(response.data);
        NotificationManager.success("Cijena uspješno izmijenjena");

        // Refresh prices after the update
        getPrices();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  useEffect(() => {
    getPrices();
  }, []);

  const handlePriceChange = (id, newValue, field) => {
    setNewPrices((prevPrices) =>
      prevPrices.map((price) =>
        price.id === id ? { ...price, [field]: newValue } : price
      )
    );
  };

  return (
    <div>
      <h3 style={{ textAlign: "center", marginTop: "1rem" }}>Uredi cijene</h3>
      <Row className="justify-content-center">
        {newPrices.map((price, index) => (
          <Card
            key={index}
            lg={3}
            style={{ width: "18rem", marginTop: "2rem", marginLeft: "1rem" }}
          >
            <Card.Body>
              {price.type === "umrlica" && (
                <Card.Title style={{ textAlign: "center" }}>Umrlica</Card.Title>
              )}
              {price.type === "poslednji_pozdrav" && (
                <Card.Title style={{ textAlign: "center" }}>
                  Poslednji pozdrav
                </Card.Title>
              )}
              {price.type === "pomen" && (
                <Card.Title style={{ textAlign: "center" }}>Pomen</Card.Title>
              )}
              {price.type === "pomen" && (
                <Card.Subtitle
                  style={{ textAlign: "center" }}
                  className="mb-2 text-muted"
                >
                  {(() => {
                    let sizeText;
                    switch (price.size) {
                      case 1:
                        sizeText = "Mali";
                        break;
                      case 2:
                        sizeText = "Srednji";
                        break;
                      case 3:
                        sizeText = "Veliki";
                        break;
                      case 4:
                        sizeText = "Najmanji";
                        break;
                      default:
                        sizeText = "Unknown Size";
                    }
                    return sizeText;
                  })()}
                </Card.Subtitle>
              )}
              {price.type === "poslednji_pozdrav" && (
                <Card.Subtitle
                  style={{ textAlign: "center" }}
                  className="mb-2 text-muted"
                >
                  {(() => {
                    let sizeText;
                    switch (price.size) {
                      case 1:
                        sizeText = "Mali";
                        break;
                      case 2:
                        sizeText = "Srednji";
                        break;
                      case 3:
                        sizeText = "Veliki";
                        break;
                      case 4:
                        sizeText = "Najmanji";
                        break;
                      default:
                        sizeText = "Unknown Size";
                    }
                    return sizeText;
                  })()}
                </Card.Subtitle>
              )}
              {price.type === "umrlica" && (
                <Card.Subtitle
                  style={{ textAlign: "center" }}
                  className="mb-2 text-muted"
                >
                  {(() => {
                    let sizeText;
                    switch (price.size) {
                      case 1:
                        sizeText = "Mala";
                        break;
                      case 2:
                        sizeText = "Srednja";
                        break;
                      case 3:
                        sizeText = "Velika";
                        break;
                      case 4:
                        sizeText = "Najmanja";
                        break;
                      default:
                        sizeText = "Unknown Size";
                    }
                    return sizeText;
                  })()}
                </Card.Subtitle>
              )}

              <p style={{ textAlign: "center" }}>
                Trenutna cijena sa jednom fotografijom:{" "}
                <span style={{ color: "green", fontWeight: "bold" }}>
                  {price.price_single}€{" "}
                </span>
              </p>
              <p style={{ textAlign: "center" }}>
                Trenutna sa vise fotografija:{" "}
                <span style={{ color: "green", fontWeight: "bold" }}>
                  {price.price_multiple}€
                </span>
              </p>
              <input
                placeholder="Cijena sa jednom fotografijom"
                type="number"
                style={{ width: "100%", borderRadius: "8px" }}
                onChange={(e) =>
                  handlePriceChange(price.id, e.target.value, "price_single")
                }
              />

              {price.size !== 4 && (
                <input
                  placeholder="Cijena sa vise fotografija"
                  type="number"
                  style={{ width: "100%", borderRadius: "8px" }}
                  onChange={(e) =>
                    handlePriceChange(
                      price.id,
                      e.target.value,
                      "price_multiple"
                    )
                  }
                />
              )}
            </Card.Body>

            <Button
              style={{ fontSize: "small" }}
              onClick={() =>
                updatePrice(
                  price.id,
                  parseFloat(price.price_single),
                  parseFloat(price.price_multiple),
                  price.type,
                  price.size
                )
              }
            >
              Sačuvaj
            </Button>
          </Card>
        ))}{" "}
        <NotificationContainer animation={false} />
      </Row>
    </div>
  );
};

export default EditPrices;
