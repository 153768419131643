const FormType = {
  Umrlica: "Umrlica",
  Pomen: "Pomen",
  PoslednjiPozdrav: "Poslednji pozdrav",
};
const FormSize = {
  Large: 3,
  Medium: 2,
  Small: 1,
  Tiny: 4,
};
export function charsNumValidation(
  formSize,
  formType,
  topTextLimit,
  nameLimit,
  mainTextLimit,
  bottomTextLimit
) {
  if (formSize === FormSize.Medium && formType === FormType.Umrlica) {
    topTextLimit = 100;
    nameLimit = 50;
    mainTextLimit = 360;
    bottomTextLimit = 280;
  } else if (formSize === FormSize.Medium) {
    topTextLimit = 100;
    nameLimit = 40;
    mainTextLimit = 360;
    bottomTextLimit = 120;
  } else if (formSize === FormSize.Large && formType === FormType.Umrlica) {
    topTextLimit = 100;
    nameLimit = 50;
    mainTextLimit = 320;
    bottomTextLimit = 280;
  } else if (formSize === FormSize.Large) {
    topTextLimit = 100;
    nameLimit = 40;
    mainTextLimit = 600;
    bottomTextLimit = 170;
  } else if (formSize === FormSize.Small && formType === FormType.Umrlica) {
    topTextLimit = 80;
    nameLimit = 50;
    mainTextLimit = 200;
    bottomTextLimit = 200;
  } else if (formSize === FormSize.Small) {
    topTextLimit = 60;
    nameLimit = 40;
    mainTextLimit = 150;
    bottomTextLimit = 70;
  }
  return { topTextLimit, nameLimit, mainTextLimit, bottomTextLimit };
}
