import React from "react";

const FunrealServices = () => {
  return (
    <div className="container" style={{ paddingTop: "140px" }}>
      <h2 className="text-center">Pogrebne usluge</h2>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Adresar groblja
      </p>

      <h3 class="mt-3">Podgorica</h3>
      <p class="mt-3">
        <strong>Pogrebne usluge d.o.o.</strong>
        <br />
        Adresa: 8. Mart bb
        <br />
        Telefon: 020/ 634-022, 067 045 751
        <br />
        Faks: 020/ 634-202
        <br />
        E-mail: pogrebneusluge@t-com.me
        <br />
        Web site:{" "}
        <a href="http://www.pogrebneuslugepg.me">www.pogrebneuslugepg.me</a>
        <br />
        Mjesne zajednice:{" "}
        <a href="http://www.podgorica.me/mjesne-zajednice">
          www.podgorica.me/mjesne-zajednice
        </a>
      </p>

      <p class="mt-3">
        <strong>Vododjelnica Lazović</strong>
        <br />
        Adresa: 8. Mart bb
        <br />
        Telefon: 067 283 991
      </p>
      <h3 class="mt-3">Danilovgrad</h3>
      <p class="mt-3">
        <strong>Javno komunalno zanatsko preduzeće</strong>
        <br />
        Adresa: Ul. Jevta Caja Šćepanovića bb
        <br />
        Telefon: 020/811-550; 020/812-720, 068/008-118
      </p>

      <h3 class="mt-3">Nikšić</h3>
      <p class="mt-3">
        <strong>Gradsko pogrebno</strong>
        <br />
        Telefon: 040/213-494 ; 067/205-848
        <br />
        Mail: jkpogrebnonk@t-com.me ; komunalnonk@t-com.me
        <br />
        Web adresa:{" "}
        <a href="http://www.pogrebnoniksic.me/">www.pogrebnoniksic.me</a>
        <br />
        Mjesne zajednice:{" "}
        <a href="http://niksic.me/o-niksicu/mjesne-zajednice/">
          niksic.me/o-niksicu/mjesne-zajednice/
        </a>
      </p>

      <p class="mt-3">
        <strong>Pogrebne usluge „Narcis“</strong>
        <br />
        Adresa: Vuka Mićunovića bb, Nikšić
        <br />
        Tel: 040/214-941, 069/503-144, 069/853-200
        <br />
        E-mail: p.u.narcis@t-com.me
      </p>

      <p class="mt-3">
        <strong>Pogrebne usluge „Lončar“</strong>
        <br />
        Adresa: Nikca od Rovina bb, Nikšić
        <br />
        Tel: 069/540-368
        <br />
        E-mail: rloncar@t-com.me
      </p>
      <h3 class="mt-3">Bar</h3>
      <p class="mt-3">
        <strong>Doo Komunalne djelatnosti</strong>
        <br />
        Adresa: Gradska kapela Belveder, Stari Bar
        <br />
        Tel: 030 342-266
        <br />
        Mob tel: 068/001-742, 068/108-172, 068/393-867
      </p>

      <p class="mt-3">
        <strong>DKM-POGREBNO MALJEVIĆ</strong>
        <br />
        Adresa: Rista Lekića 12, Bar
        <br />
        Tel: 067/064-458, 069/064-458, 068/064-458, 069/277-218
        <br />
        E-mail: dkm.maljevic@gmail.com
      </p>

      <h3 class="mt-3">Bijelo Polje</h3>
      <p class="mt-3">
        <strong>Pogrebno preduzeće Sotiroski</strong>
        <br />
        Adresa: III sandžačke brigade 63
        <br />
        Tel: 050/431-050
        <br />
        Mob tel: 069/023-690, 069/600-063
        <br />E mail: sotiroskibp@t-com.me
      </p>

      <p class="mt-3">
        <strong>
          Javno preduzeće za komunalno stambenu djelatnost “Lim” Bijelo Polje
        </strong>
        <br />
        Adresa: Ulica Živka Žižića br. 24 84000 Bijelo Polje, Crna Gora
        <br />
        Tel/fax: 050/ 432- 510
        <br />
        E-mail: jkplimbijelopolje@hotmail.com
        <br />
        Mjesne zajednice:{" "}
        <a href="http://www.bijelopolje.co.me/index.php/gradjanska-prava/mjesne-zajednice">
          www.bijelopolje.co.me/index.php/gradjanska-prava/mjesne-zajednice
        </a>
      </p>
      <h3 class="mt-3">Berane</h3>
      <p class="mt-3">
        <strong>Javno preduzeće Komunalno</strong>
        <br />
        Adresa: ul. Dragiše Radevića bb
        <br />
        Telefon: 051 233 338, 051 237 098
        <br />
        Fax: +382 51 237 099
        <br />
        E-mail: jpkomba@t-com.me
        <br />
        Mjesne zajednice:{" "}
        <a href="http://www.berane.me/index.php?IDSP=250&jezik=lat">
          www.berane.me/index.php?IDSP=250&jezik=lat
        </a>
      </p>

      <h3 class="mt-3">Budva</h3>
      <p class="mt-3">
        <strong>Javno preduzeće „Pogrebne usluge“</strong>
        <br />
        Adresa: Potkošljun bb, 85310
        <br />
        Telefon: 033/ 458-624; 030/ 458-625
        <br />
        Fax: 033/ 458-627
        <br />
        E-mail: pogrebneusluge.budva@t-com.me
        <br />
        Mjesne zajednice:{" "}
        <a href="http://new.budva.me/predsjednici-mjesnih-zajednica">
          new.budva.me/predsjednici-mjesnih-zajednica
        </a>
      </p>

      <h3 class="mt-3">Cetinje</h3>
      <p class="mt-3">
        <strong>Javno komunalno preduzeće</strong>
        <br />
        Adresa: Jaroslava Čermaka bb
        <br />
        Telefon: 041 238 225
        <br />
        Fax: 041 238 143
        <br />
        E-mail: jkp.cetinje@gmail.com
        <br />
        Mjesne zajednice:{" "}
        <a href="http://www.cetinje.me/index.php/me/gradska-uprava/mjesna-samouprava/mjesne-zajednice">
          www.cetinje.me/index.php/me/gradska-uprava/mjesna-samouprava/mjesne-zajednice
        </a>
      </p>

      <h3 class="mt-3">Herceg Novi</h3>
      <p class="mt-3">
        <strong>Javno „Komunalno-stambeno“ preduzeće</strong>
        <br />
        Adresa: Marka Vojnovića br. 1 85340 Herceg Novi
        <br />
        Telefon: 031/324-999
        <br />
        Faks: 031/324-551
        <br />
        E-mail: komunalnohn@t-com.me
        <br />
        Mjesne zajednice:{" "}
        <a href="http://www.hercegnovi.me/index.php?option=com_content&view=article&id=41">
          www.hercegnovi.me/index.php?option=com_content&view=article&id=41
        </a>
      </p>
      <h3 class="mt-3">Kolašin</h3>
      <p class="mt-3">
        <strong>Javno komunalno preduzece</strong>
        <br />
        Adresa: Mirka Vešovića b.b. Kolašin
        <br />
        Telefon: 020/ 865- 455
      </p>

      <h3 class="mt-3">Kotor</h3>
      <p class="mt-3">
        <strong>Javno komunalno preduzeće „Kotor“</strong>
        <br />
        Adresa: Škaljari bb, 85330 Kotor
        <br />
        Telefon/fax: 032/325-677
        <br />
        Web: <a href="http://www.jkpkotor.com">www.jkpkotor.com</a>
        <br />
        E-mail: kapela@t-com.me
        <br />
        Mjesne zajednice:{" "}
        <a href="http://kotor.me/me/mjesne-zajednice">
          kotor.me/me/mjesne-zajednice
        </a>
      </p>

      <p class="mt-3">
        <strong>Pogrebne usluge „Poslednji ispraćaj“</strong>
        <br />
        Adresa: Poslovni centar Škaljari-Rakite, Kotor
        <br />
        Tel: 069/645-022, 069/571-049, 069/233-391
        <br />
        E-mail: sandrajksmvc@yahoo.com
        <br />
        Website: <a href="http://www.pogrebno.me">www.pogrebno.me</a>
      </p>

      <h3 class="mt-3">Opština Mojkovac</h3>
      <p class="mt-3">
        Telefon: 050/470-272; 050/472-715
        <br />
        Web adresa: opstinamojkovac@t-com.me
        <br />
        Mjesne zajednice:{" "}
        <a href="http://www.mojkovac.me/index.php?option=com_content&view=article&id=3&It">
          www.mojkovac.me/index.php?option=com_content&view=article&id=3&It
        </a>
      </p>

      <h3 class="mt-3">Plužine</h3>
      <p class="mt-3">
        Javno komunalno stambeno preduzeće
        <br />
        Telefon: 040/ 271-113
      </p>

      <h3 class="mt-3">Rožaje</h3>
      <p class="mt-3">
        <strong>Javno preduzeće “Komunalno”</strong>
        <br />
        Adresa: Jaha Kurtagica bb, 84 310 Rožaje
        <br />
        Telefon: 051/271-330; Mob: 068/838-500;
        <br />
        E-mail: jpk.r@live.com;
        <br />
        Web adresa:{" "}
        <a href="http://www.komunalnorozaje.me">www.komunalnorozaje.me</a>
        <br />
        Mjesne zajednice:{" "}
        <a href="http://www.rozaje.me/?page_id=1631">
          www.rozaje.me/?page_id=1631
        </a>
      </p>
      <h3 class="mt-3">Opština Šavnik</h3>
      <p class="mt-3">
        Telefon: 040/ 266- 103; 040/ 266- 108
        <br />
        Faks: 040/266-231
        <br />
        E-mail: sosavnik@t-com.me
        <br />
        Web adresa: <a href="http://www.savnik.me">www.savnik.me</a>
      </p>

      <h3 class="mt-3">Tivat</h3>
      <p class="mt-3">
        <strong>DOO „Komunalno“</strong>
        <br />
        Adresa: Luke Tomanovića br. 7, Tivat.
        <br />
        Telefon: 032/671-527
        <br />
        Fax: 032/671-039
        <br />
        E-mail: kom-tivat@t-com.me
        <br />
        Web adresa:{" "}
        <a href="http://www.komunalnotivat.com/">www.komunalnotivat.com</a>
        <br />
        Mjesne zajednice:{" "}
        <a href="http://opstinativat.com/lokalna-samouprava/mjesne-zajednice.html">
          opstinativat.com/lokalna-samouprava/mjesne-zajednice.html
        </a>
      </p>

      <h3 class="mt-3">Ulcinj</h3>
      <p class="mt-3">
        <strong>Javno komunalno preduzece</strong>
        <br />
        Adresa: Ul. Majke Tereze bb Ulcinj
        <br />
        Telefon/: 425-003 085/425 – 004
        <br />
        Fax: 085/ 425 -005
        <br />
        Web adresa: <a href="http://www.jpkd-ul.me">www.jpkd-ul.me</a>
      </p>

      <h3 class="mt-3">Opština Zabljak</h3>
      <p class="mt-3">
        Adresa: Trg Durmitorskih ratnika br. 1<br />
        Telefon: 052-361-419
        <br />
        E-mail: savazekovic@gmail.com
        <br />
        Web adresa: <a href="http://zabljak.me/">zabljak.me</a>
      </p>

      <h3 class="mt-3">Pljevlja</h3>
      <p class="mt-3">
        <strong>Javno preduzeće Komunalne usluge</strong>
        <br />
        Adresa: Mila Peruničića bb 84210 Pljevlja
        <br />
        Web adresa: <a href="http://www.pljevlja.me/">www.pljevlja.me</a>
      </p>
    </div>
  );
};

export default FunrealServices;
